import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Row, Container, Col } from 'react-bootstrap';
import {Puff} from 'react-loader-spinner';
import NavbarSection from "./NavbarSection";
import ThemeContext from './Themes/ThemeContext';
import NewsletterForm from './Forms/NewsletterForm';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    WhatsappIcon,
    WhatsappShareButton
} from 'react-share';
import './StylingArena/ArticleHive.css'

const API_TOKEN = process.env.REACT_APP_API_TOKEN;
const nigerianFlagEmoji = '\u{1F1F3}\u{1F1EC}';

const ArticleHive = (props) => {
const { darkMode, toggleDarkMode } = useContext(ThemeContext);
  const [article, setArticle] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { slug } = useParams();
  const navigate = useNavigate();

  const shareUrl = `https://techhive.ng/blog/${slug}`;
  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await fetch(`https://backend.techhive.ng/api/articles/${slug}`, {
            headers: {
              'Authorization': `Token ${API_TOKEN}`,
              'Content-Type': 'application/json',
            },
          });
        if (!response.ok) {
            throw new Error('Network response was not ok');
          }
        const data = await response.json();

        setArticle(data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching article data:', error);
        navigate('/error'); // Redirect to the error page.
      }
    };

    fetchArticle();
  }, [slug, navigate]);

if (isLoading) {
  return (
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
      <Puff
         type="Puff"
         color="#DAA520"
         height={100}
         width={100}
         timeout={3000} //3 secs
      />
    </div>
  );
}

  if (!article) {
    return <div>Article not found</div>;
  }

  return (
    <div className={` py-3 ${darkMode ? 'dark-mode' : ''}`}>
        <NavbarSection onToggle={toggleDarkMode}/>
        <Container>
            <Row>
                <Col lg="8" md="6" sm="12" className="py-3 my-3">
                <h1>{article.title}</h1>
        <img src={`https://backend.techhive.ng${article.image}`} alt={`An visual describing the topic- ${article.title} `} className='img-fluid my-3 py-2' />
      <div dangerouslySetInnerHTML={{ __html: article.clean_content }} />
      <div>
        {article.tags && (
          <div>
        <h4>Tags</h4>
        <ul>
          {article.tags.map((tag) => (
            <li key={tag}>{tag}</li>
            ))}
        </ul>
            <div> Author: <a href={article.author_website} target='blank' className='writtenby'>{article.author}</a></div>
      </div>
    )}
      </div>
      {/* Display other fields as needed */}</Col>
                <Col className="py-3 my-3">
                <div className="py-3 my-3">
        <h2>Share this article:</h2>
        <FacebookShareButton url={shareUrl}>
          <FacebookIcon size={32} round />
        </FacebookShareButton>
        <TwitterShareButton url={shareUrl}>
          <TwitterIcon size={32} round />
        </TwitterShareButton>
        <LinkedinShareButton url={shareUrl}>
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>
        <WhatsappShareButton url={shareUrl}>
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
      </div>
                <NewsletterForm/>

                <div  className="py-3 my-3">
                  
                  <h4>Follow Us</h4>
              <p>Stay connected to the hive!!</p>
              <div className="social-icons justify-content-center">
        <a href="https://www.linkedin.com/company/techhiveng/" target="_blank" rel="noopener noreferrer" className="social-icon mx-2">
          <i className="bi bi-linkedin"></i>
        </a>
        <a href="https://twitter.com/techhivenig" target="_blank" rel="noopener noreferrer" className="social-icon mx-2">
          <i className="bi bi-twitter"></i>
        </a>
        <a href="https://www.facebook.com/techhiveng" target="_blank" rel="noopener noreferrer" className="social-icon mx-2">
          <i className="bi bi-facebook"></i>
        </a>
        <a href="https://www.youtube.com/@techhiveng" target="_blank" rel="noopener noreferrer" className="social-icon mx-2">
          <i className="bi bi-youtube"></i>
        </a>
        <a href="https://www.instagram.com/techhivenig" target="_blank" rel="noopener noreferrer" className="social-icon mx-2">
          <i className="bi bi-instagram"></i>
        </a>
        <a href="https://chat.whatsapp.com/H1J8F7Ps2pTHFGXjyddAwg" target="_blank" rel="noopener noreferrer" className="social-icon mx-2">
        <i className="bi bi-whatsapp"></i>
      </a>
      </div>
                  </div>
                </Col>
            </Row>
            <footer className="py-3 my-3">
         
               <p>TechHive {nigerianFlagEmoji} © 2023  | All Rights Reserved</p>
               <p className='designedbycol'>Developed With 💓 by &copy; <a href="https://justrobin.dev" target='blank' className='designedby'>Robin Okwanma</a></p>
    
            </footer>
        </Container>
    </div>
  );

  // ... other component code
};

export default ArticleHive