import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import FooterSection from "./FoooterSection";
import ThemeContext from './Themes/ThemeContext';
import './StylingArena/ConfirmEmail.css'


// const API_TOKEN = process.env.REACT_APP_API_TOKEN;
const ConfirmEmail = () => {
    // eslint-disable-next-line
    const { darkMode, toggleDarkMode } = useContext(ThemeContext);
    const { token } = useParams();
    const [message, setMessage] = useState('');

    useEffect(() => {
        const confirmEmail = async () => {
            try {
                 await axios.get(
                    `https://backend.techhive.ng/api/confirm-email/${token}/`,
                    {}, // Empty data object
                    {
                        headers: {
                            'Authorization': `Token ${token}`,
                            'Content-Type': 'application/json',
                        },
                    }
                );
                setMessage('Email confirmed! You can now log in.');
            } catch (error) {
                setMessage('Error: Something went wrong. Please contact support.');
                console.log(error)
            }
        };
        confirmEmail();
    }, [token]);

    return (
        <div className={`  ${darkMode ? 'dark-mode' : ''}`}>
                  <div className="text-center py-2">
      <Link to={"/"} >
        <img src="/images/techhivelogo.png" alt="Techhive" className="image-fluid techhivelogo" />
              </Link>
      </div>
            <section className='py-5'>

            <h3 className='confirmheader text-center py-4'>Confirm Email</h3>
            <h4 className='text-center py-4'>{message}</h4>
            </section>
            <FooterSection darkMode={darkMode}/>
        </div>
    );
};

export default ConfirmEmail;
