import React, { useState, useEffect, useContext } from "react";
import { Card, Button, Modal } from "react-bootstrap";
import NavbarSection from "./NavbarSection";
import FooterSection from "./FoooterSection";
import ThemeContext from './Themes/ThemeContext';
import  "./StylingArena/Hackathons.css";

function formatDate(dateString) {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short'
  };
  const date = new Date(dateString);
  return date.toLocaleString('en-US', options);
}


const API_TOKEN = process.env.REACT_APP_API_TOKEN;
function Hackathons() {
  const { darkMode, toggleDarkMode } = useContext(ThemeContext);
  const [hackathons, setHackathons] = useState([]);
  const [scholarships, setScholarships] = useState([]);
  const [showHackathonModal, setShowHackathonModal] = useState(false);
  const [showScholarshipModal, setShowScholarshipModal] = useState(false);
  const [selectedHackathon, setSelectedHackathon] = useState(null);
  const [selectedScholarship, setSelectedScholarship] = useState(null);
  useEffect(() => {
    const fetchHackathons = async () => {
      try {
        const response = await fetch("https://backend.techhive.ng/api/hackathons", {
          headers: {
            'Authorization': `Token ${API_TOKEN}`,
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        setHackathons(data.results);
      } catch (error) {
        console.error("Error fetching hackathons:", error);
      }
    };
    
    fetchHackathons();
  }, []);
  useEffect(() => {
    const fetchHackathons = async () => {
      try {
        const response = await fetch("https://backend.techhive.ng/api/scholarships", {
          headers: {
            'Authorization': `Token ${API_TOKEN}`,
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        setScholarships(data.results);
      } catch (error) {
        console.error("Error fetching Scholarships:", error);
      }
    };
    
    fetchHackathons();
  }, []);

  function handleHackathonModal(hackathon = null) {
    setSelectedHackathon(hackathon);
    setShowHackathonModal(!showHackathonModal);
  }

  function handleScholarshipModal(scholarship = null) {
    setSelectedScholarship(scholarship);
    setShowScholarshipModal(!showScholarshipModal);
  }

  function formatText(text) {
    return text.split('\n').map((item, key) => {
      return <span key={key}>{item}<br/></span>
    })
  }
  
  return (
    <div className={` ${darkMode ? 'dark-mode' : ''}`}>
      <NavbarSection onToggle={toggleDarkMode}/>

      <h1 className="text-center hackathonhead  my-3 py-3 ">Participate in Challenging Hackathons</h1>
      {hackathons.map((hackathon) => (
        <div key={hackathon.id} className="text-center my-3 py-3 mx-3 hackathoncard">
          <Card className="card-custom" style={{ width: "20rem",  }}>
            <Card.Body>
              <Card.Img variant="top" src={hackathon.image} height={200}/>
              <Card.Title>{hackathon.title}</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                {formatDate(hackathon.datetime)}
              </Card.Subtitle>
              <Card.Text>{formatText(hackathon.description)}</Card.Text>
              <Card.Link className="btn btn-warning" onClick={() => handleHackathonModal(hackathon)}>More Details</Card.Link>
              <Card.Link className="btn btn-warning">Register</Card.Link>
            </Card.Body>
          </Card>
        </div>
      ))}

<h1 className="text-center scholarshiphead  my-3 py-3 ">Ongoing & Upcoming Scholarships</h1>
      {scholarships.map((scholarship) => (
        <div key={scholarship.id} className="text-center my-3 py-3 mx-3 scholarshipcard">
          <Card className="card-custom" style={{ width: "20rem",  }}>
            <Card.Body>
              <Card.Img variant="top" src={scholarship.image} />
              <Card.Title>{scholarship.title}</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                Deadline- {formatDate(scholarship.deadline)}
              </Card.Subtitle>
              <Card.Text>{formatText(scholarship.description)}</Card.Text>
              <Card.Link className="btn btn-warning" onClick={() => handleScholarshipModal(scholarship)}>More Details</Card.Link>
              <Card.Link className="btn btn-warning">Apply</Card.Link>
            </Card.Body>
          </Card>
        </div>
      ))}

 
    <Modal show={showHackathonModal} onHide={() => handleHackathonModal()}>
        <Modal.Header className="bg-warning" closeButton>
          <Modal.Title>{selectedHackathon && selectedHackathon.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={` modal-body-custom ${darkMode ? 'dark-mode' : ''}`}>
          <p><strong>Date:</strong> {selectedHackathon && formatDate(selectedHackathon.datetime)}</p>
          <p><strong>Location:</strong> {selectedHackathon && selectedHackathon.location}</p>
          <p><strong>Capacity:</strong> {selectedHackathon && selectedHackathon.capacity}</p>
          <p><strong>Description:</strong> {selectedHackathon && formatText(selectedHackathon.description)}</p>
          <br />
          <p><strong>Registration Details:</strong> {selectedHackathon && formatText(selectedHackathon.reg_details)}</p>    
          <p><strong>Prize:</strong> {selectedHackathon && selectedHackathon.prize}</p>
          <br />
          <p><strong>Rules:</strong> {selectedHackathon && formatText(selectedHackathon.rules)}</p>
          {selectedHackathon && selectedHackathon.speakers && (
  <div>
    <strong>Speakers:</strong>
    {selectedHackathon.speakers.map((speaker, index) => (
      <div key={index}>
        <p>{speaker.name}</p>
        <img src={speaker.image} alt="" className="img-fluid" width={200}/>
        <p>{speaker.bio}</p>
        {/* Add other organizer properties if needed */}
      </div>
    ))}
  </div>
)}
          {selectedHackathon && selectedHackathon.sponsors && (
  <div>
    <strong><u>Sponsored By: </u></strong>
    {selectedHackathon.sponsors.map((sponsor, index) => (
      <div key={index}>
        <p>{sponsor.name}</p>
        <img src={sponsor.image} alt="" className="img-fluid modalsponsor" width={150}/>
        {/* <p>{sponsor.description}</p> */}
        {/* Add other organizer properties if needed */}
      </div>
    ))}
  </div>
)}
          {/* Add more data from the API response here */}
        </Modal.Body>
        <Modal.Footer className="bg-warning">
          <Button variant="secondary" onClick={() => handleHackathonModal()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showScholarshipModal} onHide={() => handleScholarshipModal()}>
        <Modal.Header className="bg-warning" closeButton>
          <Modal.Title>{selectedScholarship && selectedScholarship.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={` modal-body-custom ${darkMode ? 'dark-mode' : ''}`}>
          <p><strong>Date:</strong> {selectedScholarship && formatDate(selectedScholarship.deadline)}</p>
          <p><strong>Description:</strong> {selectedScholarship && formatText(selectedScholarship.description)}</p>
          <br />
          <p><strong>Requirements:</strong> {selectedScholarship && formatText(selectedScholarship.requirements)}</p>    
          <p><strong>Amount:</strong> {selectedScholarship && selectedScholarship.prize}</p>
          <br />
          {/* <p><strong>Eligibility Criteria:</strong> {selectedScholarship && selectedScholarship.sponsors}</p> */}
          {/* Add more data from the API response here */}
          {selectedScholarship && selectedScholarship.sponsors && (
  <div>
    <strong>Sponsored By:</strong>
    {selectedScholarship.sponsors.map((sponsor, index) => (
      <div key={index}>
        <p>{sponsor.name}</p>
        <img src={sponsor.image} alt="" className="img-fluid modalsponsor"/>
        
        {/* Add other organizer properties if needed */}
      </div>
    ))}
  </div>
)}
        </Modal.Body>
        <Modal.Footer className="bg-warning">
          <Button variant="secondary" onClick={() => handleScholarshipModal()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <FooterSection darkMode={darkMode}/>
    </div>

    
  );
}

export default Hackathons;
