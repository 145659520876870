import React, { useContext, useState, useEffect } from "react";
import ThemeContext from "./Themes/ThemeContext";
import { Col, Card, Button, Modal } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";
import "./StylingArena/Courses.css";

const API_TOKEN = process.env.REACT_APP_API_TOKEN;
function Courses() {
  const { darkMode, toggleDarkMode } = useContext(ThemeContext);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [courses, setCourses] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const [selectedCourse, setSelectedCourse] = useState(null);

  useEffect(() => {
    async function fetchCourses() {
      try {
        const response = await fetch(
          "https://backend.techhive.ng/api/courses",
          {
            headers: {
              Authorization: `Token ${API_TOKEN}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setCourses(data.results);
        console.log(data);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    }
    fetchCourses();
  }, []);
  function handleModal(course = null) {
    setSelectedCourse(course);
    setShowModal(!showModal);
  }
  function formatText(text) {
    return text.split("\n").map((item, key) => {
      return (
        <span key={key}>
          {item}
          <br />
        </span>
      );
    });
  }
  useEffect(() => {
    setSidebarOpen(false);
  }, [location]);
  return (
    <div
      className={`  ${darkMode ? "dark-mode" : ""}`}
      style={{ display: "flex" }}
    >
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        onToggle={toggleDarkMode}
      />
      <div className="divright">
        <nav>
          <div
            className="bg-warning p-1 sticky-top "
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            {" "}
            <h2 onClick={() => setSidebarOpen(!sidebarOpen)}>
              {sidebarOpen ? (
                <i className="bi bi-list p-2"></i>
              ) : (
                <i className="bi bi-list p-2"></i>
              )}
            </h2>
            <div className="my-1">
              {/* <button className="btn btn-warning" onClick={handleLogoutx}>
              <FaArrowLeft/> Logout
            </button> */}
              <button className="btn " onClick={toggleDarkMode}>
                {darkMode ? (
                  <h4>
                    <i className="bi bi-sun-fill text-success"></i>
                  </h4>
                ) : (
                  <h4>
                    <i className="bi bi-moon-fill text-black"></i>
                  </h4>
                )}
              </button>
            </div>
          </div>
        </nav>
        <div className="coursecontainerright mx-2">
          <h2 className="text-center my-2 py-2 communtyheader">
            Learn and Grow
          </h2>
          <h4 className="text-center my-2 py-2">
            Premium, Personalized Courses
          </h4>
          <div>
            {courses.map((course) => (
              <Col
                key={course.id}
                lg="3"
                md="6"
                sm="12"
                className="my-3 py-3 mx-2 "
              >
                <Card className="card-custom" style={{ width: "20rem"}}>
                  <Card.Body>
                    <Card.Img variant="top" src={course.image} />
                    <Card.Title>{course.title}</Card.Title>
                    <Card.Text>
                      <p>
                        <b>Maximum Capacity-</b> {course.max_capacity}
                      </p>
                    </Card.Text>
                    <Card.Text>
                      <p>
                        <b>Slots Left-</b> {course.slots_left}
                      </p>
                    </Card.Text>
                    <Card.Text>
                      <p>
                        <b>Amount-</b> ₦{course.amount}
                      </p>
                    </Card.Text>
                    <Card.Link
                      className="btn btn-warning"
                      onClick={() => handleModal(course)}
                    >
                      More Details
                    </Card.Link>
                    <Card.Link className="btn btn-warning">Register</Card.Link>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </div>
        </div>
        <Modal show={showModal} onHide={() => handleModal()}>
          <Modal.Header className="bg-warning" closeButton>
            <Modal.Title>
              <strong>{selectedCourse && selectedCourse.title}</strong>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            className={` modal-body-custom ${darkMode ? "dark-mode" : ""}`}
          >
            <p>
              <strong>Capacity:</strong>{" "}
              {selectedCourse && selectedCourse.max_capacity}
            </p>
            <p>
              <strong>Slots Left:</strong>{" "}
              {selectedCourse && selectedCourse.slots_left}
            </p>
            <p>
              <strong>Description:</strong>{" "}
              {selectedCourse && formatText(selectedCourse.description)}
            </p>
            <br />
            <p>
              <strong>Amount:</strong> ₦
              {selectedCourse && selectedCourse.amount}
            </p>
            <br />

            {selectedCourse && selectedCourse.instructor && (
              <div>
                <strong>Instructor(s):</strong>
                {selectedCourse.instructor.map((instructord, index) => (
                  <div key={index}>
                    <p>Name- {instructord.name}</p>
                    <p>About- {instructord.bio}</p>
                    <p>Contact- {instructord.email}</p>
                    {/* Add other organizer properties if needed */}
                  </div>
                ))}
              </div>
            )}
            {selectedCourse && selectedCourse.sponsors && (
              <div>
                <strong>Sponsors:</strong>
                {selectedCourse.sponsors.map((sponsor, index) => (
                  <div key={index}>
                    <p>{sponsor.name}</p>
                    <img src={sponsor.image} alt="" className="img-fluid" />
                    <p>{sponsor.description}</p>
                    {/* Add other organizer properties if needed */}
                  </div>
                ))}
              </div>
            )}
            {/* Add more data from the API response here */}
          </Modal.Body>
          <Modal.Footer className="bg-warning">
            <Button variant="secondary" onClick={() => handleModal()}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default Courses;
