import React, { useEffect, useContext } from "react";
// import { NavDropdown, Nav } from 'react-bootstrap';
import ThemeContext from "./Themes/ThemeContext";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import styled from 'styled-components';
import { FaUser, FaGraduationCap, FaPeopleArrows, FaBriefcase, FaFlag, FaArrowLeft, FaNewspaper } from "react-icons/fa";
import "./StylingArena/Sidebar.css";

const SidebarContainer = styled.div`
  width: 250px;
  background: #333;
  color: #fff;
  height: 100vh;
  overflow: auto;
  padding: 10px;
`;

const SidebarLink = styled(NavLink)`
  color: #fff;
  text-decoration: none;
  margin: 15px 5px;
  display: flex;
  align-items: center;
  overflow-x: hidden;

  &:hover {
    color: #4db2ec;
  }

  &.active {
    color: goldenrod;
  }
`;

const Sidebar = ({ sidebarOpen, setSidebarOpen }) => {
  // const [sidebarOpen, setSidebarOpen] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { darkMode, toggleDarkMode } = useContext(ThemeContext);

  const handleLogoutx = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    setSidebarOpen(false);
  }, [location, setSidebarOpen]);


  return (
    <div>
      {/* <nav>
  <div className="bg-warning p-1 sticky-top ">      <h2 onClick={() => setSidebarOpen(!sidebarOpen)}>
        {sidebarOpen ? <i className="bi bi-list p-2"></i> : <i className="bi bi-list p-2"></i>}
      </h2></div>
</nav> */}
      {sidebarOpen && (
        <SidebarContainer>
          <img
            src="/images/techhivelogo.png"
            alt="Techhive"
            className="image-fluid techhivelogomini my-3"
          />
          <SidebarLink to="/profile" activeClassName="active">
            <FaUser className="mx-3"/> Profile
          </SidebarLink>
          <SidebarLink to="/courses" activeClassName="active">
            <FaGraduationCap className="mx-3"/> Courses
          </SidebarLink>
          <SidebarLink to="/community" activeClassName="active">
            <FaPeopleArrows className="mx-3"/> Community
          </SidebarLink>
          {/* <NavDropdown title="Community" id="basic-nav-dropdown" className="mx-3">
          <FaPeopleArrows className="mx-3"/>
  <NavDropdown.Item href="#action/1">Community 1</NavDropdown.Item>
  <NavDropdown.Item href="#action/2">Community 2</NavDropdown.Item>
  <NavDropdown.Item href="#action/3">Community 3</NavDropdown.Item>
</NavDropdown> */}

          <SidebarLink to="/jobs" activeClassName="active">
            <FaBriefcase className="mx-3"/> Job Section
          </SidebarLink>
          <SidebarLink to="/blog" activeClassName="active">
            <FaNewspaper className="mx-3"/>Visit Blog
          </SidebarLink>
          <SidebarLink to="/report" activeClassName="active">
            <FaFlag className="mx-3"/> Report an Issue
          </SidebarLink>
          <div className="my-5">
            <button className="btn btn-warning" onClick={handleLogoutx}>
              <FaArrowLeft/> Logout
            </button>
            <button className="btn " onClick={toggleDarkMode}>
              {darkMode ?  <h4><i className="bi bi-sun-fill text-success">
                </i></h4> :  <h4><i className="bi bi-moon-fill text-black">
                </i></h4>
                }
            </button>
          </div>
        </SidebarContainer>
      )}
    </div>
  );
};

export default Sidebar;
