import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { Form, Button, Modal } from 'react-bootstrap'; // add Modal from react-bootstrap
import { useLocation } from 'react-router-dom';
import ThemeContext from './Themes/ThemeContext';
import Sidebar from './Sidebar'
import "./StylingArena/Report.css"

const API_TOKEN = process.env.REACT_APP_API_TOKEN;

const Report = () => {
    const { darkMode, toggleDarkMode } = useContext(ThemeContext);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const location = useLocation();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        complaint: ''
    });

    // Adding state for the Modal
    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);

    // Check if the form was submitted in the last 24 hours
    const isDisabled = new Date().getTime() - (localStorage.getItem('formSubmitted') || 0) < 24 * 60 * 60 * 1000;

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isDisabled) return; // Prevent submission if the form was submitted less than 24 hours ago

        try {
            const response = await axios.post(
                'http://backend.techhive.ng/api/complaints/', 
                formData,
                {
                    headers: {
                        'Authorization': `Token ${API_TOKEN}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            
            console.log(response.data);

            // Save the current timestamp in localStorage
            localStorage.setItem('formSubmitted', new Date().getTime());

            // Show the success modal
            handleShowModal();

        } catch(error) {
            console.error('Error:', error);
        }
    }

    useEffect(() => {
        setSidebarOpen(false);
      }, [location]);
    
    return (
        <div className={`  ${darkMode ? 'dark-mode' : ''}`}  style={{ display: 'flex',}}>
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} onToggle={toggleDarkMode}/>
        <div className='reportdivright'>
        <nav>
  <div className="bg-warning p-1 sticky-top "  style={{ display: 'flex',  justifyContent: 'space-between'}}>      <h2 onClick={() => setSidebarOpen(!sidebarOpen)}>
        {sidebarOpen ? <i className="bi bi-list p-2"></i> : <i className="bi bi-list p-2"></i>}
      </h2>
      <div className="my-1">
              {/* <button className="btn btn-warning" onClick={handleLogoutx}>
              <FaArrowLeft/> Logout
            </button> */}
              <button className="btn " onClick={toggleDarkMode}>
                {darkMode ? (
                  <h4>
                    <i className="bi bi-sun-fill text-success"></i>
                  </h4>
                ) : (
                  <h4>
                    <i className="bi bi-moon-fill text-black"></i>
                  </h4>
                )}
              </button>
            </div>
      </div>
</nav>
<div className='reportcontainerright text-center'>
    <h3 className='reportheader my-3 '>We are Here for you</h3>
    <h4 className='p-2'>A problem, emergency or question? <br /> Please do not hesitate to contact the help center, we can assist you.</h4>

        <Form onSubmit={handleSubmit} className='my-5 py-3'>
            <Form.Label>
                Full Name
            <Form.Control  placeholder="Your Full Name" type="text" name="name" onChange={handleChange} value={formData.name} />
            </Form.Label>
            <br />
            <Form.Label>
                Email
            <Form.Control  placeholder="Email Address" type="email" name="email" onChange={handleChange} value={formData.email} />
            </Form.Label>
            <br />
            <Form.Label>
                Subject
            <Form.Control  placeholder="Subject Matter" type="text" name="subject" onChange={handleChange} value={formData.subject} />
            </Form.Label>
            <br />
            <Form.Label>
            Message Body
            <Form.Control as="textarea"
                placeholder="Type your complaint here" name="complaint" onChange={handleChange} value={formData.complaint} />
            </Form.Label>
            <br />
            <Button variant='warning' type="submit" value="Submit" disabled={isDisabled}>
            Submit
        </Button>
        </Form>

        {/* // Add a Modal to display after form submission */}
        <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
            <Modal.Title>Success</Modal.Title>
            </Modal.Header>
            <Modal.Body>Your form was submitted successfully!</Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>
        </div>
</div>

        </div>
    )
}

export default Report;
