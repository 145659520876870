import React, { useContext, useState } from "react";
import { Col, Row, Card, Container, Accordion } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import NavbarSection from "./NavbarSection";
import "./StylingArena/HomePage.css";
import MovingComponent from "react-moving-text";
import FooterSection from "./FoooterSection";
import ThemeContext from "./Themes/ThemeContext";

const FAQ = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAnswer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Card className="card-custom">
      <Card.Header>
        <h6 className="fw-bold" onClick={toggleAnswer}>
          <i class="bi bi-arrow-right-short"></i> {question}
        </h6>
      </Card.Header>
      <Accordion.Collapse in={isOpen}>
        <Card.Body>{answer}</Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};
const faqs = [
  {
    question: "What is Tech Hive all about?",
    answer:
      "Tech Hive started from a drive to bring tech enthusiasts together to be able to learn, connect and grow together through engagements, programs and opportunities.",
  },
  {
    question: "Is there a community membership cost?",
    answer: "No, membership is completely free!",
  },
  {
    question: "Who can join Tech Hive? Is there an age restriction?",
    answer:
      "There is no age restriction, TechHive is for all Tech enthusiasts of all ages",
  },
  {
    question: "Where is TechHive located?",
    answer:
      "TechHive was born in portharcourt Nigeria. It is a virtual community with members from all over the world. The community is hosted virtually with activities hosted regularly to connect with members. Most meet ups and physical events may be hosted in portharcourt ",
  },
  {
    question: "How can I talk to other members of the community?",
    answer:
      "You can interact with other members of our community on Slack or on our various Whatsapp channels",
  },
  {
    question:
      "How can I hear about upcoming scholarships, hackathons and other news?",
    answer:
      "You can get our latest news and engagement from our Newsletter here and from our Twitter and Instagram accounts. ",
  },
  {
    question: "I am a student, How can i volunteer for TechHive on campus?",
    answer:
      "To volunteer and represent us in your school or university, contact us via email on campus@techhive.ng and we will reach out to you. ",
  },
  {
    question: "How can I partner with TechHive?",
    answer:
      "To partner with us or sponsor any of our programs, scholarships and events, kindly reach out to us here: partnerships@techhive.ng",
  },
  {
    question: "Why should I partner with TechHive?",
    answer:
      "TechHive is the fastest growing tech community in Nigeria. We welcome brands that align with our community ethics and partner to deliver developer-focused programs",
  },
];

function HomePage() {
  const { darkMode, toggleDarkMode } = useContext(ThemeContext);
  let navigate = useNavigate();
  const token = localStorage.getItem("token");
  const handleAuth = () => {
    // Redirect to the login page
    navigate("/community");
  };

  
  return (
    <div className={darkMode ? "dark-mode" : ""}>
      <NavbarSection onToggle={toggleDarkMode} />
      <section className="m-3 p-3 homeintroimg homesection">
        <Row>
          <Col md="6" sm="2">
            <MovingComponent
              type="fadeInFromBottom"
              className="techhiveaim"
              duration="800ms"
              delay="0s"
              direction="normal"
              timing="ease"
              iteration="1"
              fillMode="none"
            >
              <p className="homeintro mark-font">Welcome To Tech Hive</p>
              <p className="mark-font">
                A Space Where Techies Thrive Unopposed.
              </p>
            </MovingComponent>

            <MovingComponent
              type="fadeInFromBottom"
              duration="1200ms"
              delay="0s"
              direction="normal"
              timing="ease"
              iteration="1"
              fillMode="none"
            >
              <p className="homeintro ">
                Leveraging Technology to Drive Innovation, Growth and
                Development in Africa.
              </p>
            </MovingComponent>

            <MovingComponent
              type="fadeInFromBottom"
              duration="1500ms"
              delay="0s"
              direction="normal"
              timing="ease"
              iteration="1"
              fillMode="none"
            >
              {token ? (
                <Link
                  to={"/login"}
                  onClick={handleAuth}
                  className="btn btn-lg bg-warning py-3 my-3 mx-3"
                >
                  <i class="bi bi-box-arrow-in-right"></i> Login
                </Link>
              ) : (
                <>
                  <Link
                    to={"/join-us"}
                    className="btn btn-lg bg-warning py-3 my-3 mx-3"
                  >
                    Join Us
                  </Link>
                  <Link
                    to={"/contact"}
                    className="btn btn-lg bg-warning py-3 my-3 mx-3"
                  >
                    Contact Us
                  </Link>
                </>
              )}
            </MovingComponent>
            {/* <Link to={'/join-us'} className='btn btn-lg bg-warning py-3 my-3 mx-3'>Join Us</Link>
<Link to={'/contact'} className='btn btn-lg bg-warning py-3 my-3 mx-3'>Contact Us</Link> */}
          </Col>

          <Col md="6" sm="2">
            <MovingComponent
              type="fadeInFromBottom"
              duration="1800ms"
              delay="0s"
              direction="normal"
              timing="ease"
              iteration="1"
              fillMode="none"
            >
              <img
                class="img-fluid hero-img"
                src="/images/community1.jpg"
                alt=""
              />
            </MovingComponent>
          </Col>
        </Row>
      </section>
      <Container>
        <h1 className="whatwedo text-center my-3 py-3">What We Do</h1>
        <Row className="aimrow  my-3 py-3 mx-2">
          <Card
            className="card-custom mx-3 my-2 py-2"
            style={{ width: "22rem" }}
          >
            <Card.Body>
              <h1>
                <i class="bi bi-people"></i>
              </h1>
              <Card.Title>
                <h4>Community</h4>
              </Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                A warm sense of belonging
              </Card.Subtitle>
              <Card.Text>
                Communities provide essential support for success, and we embody
                that for driven innovators. We back your vision by fostering a
                nurturing environment.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card
            className="card-custom mx-3 my-2 py-2"
            style={{ width: "22rem" }}
          >
            <Card.Body>
              <h1>
                <i class="bi bi-chat"></i>
              </h1>
              <Card.Title>
                <h4>Connect</h4>
              </Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                Valuable Meet Ups
              </Card.Subtitle>
              <Card.Text>
                While we don't mind meeting up on zoom from anywhere in the
                world, we look forward to having a cup of coffee with you
                physically. Our meetups are not limited to specific locations,
                because we bring the magic to you most of the time.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card
            className="card-custom mx-3 my-2 py-2"
            style={{ width: "22rem" }}
          >
            <Card.Body>
              <h1>
                <i class="bi bi-book"></i>
              </h1>
              <Card.Title>
                {" "}
                <h4>Educate</h4>
              </Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                Igniting intellectual curiosity
              </Card.Subtitle>
              <Card.Text>
                Education empowers individuals to unlock their potential, and we
                are dedicated to fostering a learning environment. We equip
                learners with essential knowledge and skills, paving the way for
                growth and success.
              </Card.Text>
            </Card.Body>
          </Card>
        </Row>
        <Row className="aimrow my-3 py-3 mx-2">
          <Card
            className="card-custom mx-3 my-2 py-2"
            style={{ width: "22rem" }}
          >
            <Card.Body>
              <h1>
                <i class="bi bi-newspaper"></i>
              </h1>
              <Card.Title>
                <h4>Content</h4>
              </Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                Rich resources for boundless exploration
              </Card.Subtitle>
              <Card.Text>
                Our tech community curates a wealth of engaging resources, from
                insightful articles and tutorials to in-depth webinars and
                podcasts. These valuable assets empower members to explore new
                horizons and stay ahead in the fast-paced world of technology
              </Card.Text>
            </Card.Body>
          </Card>
          <Card
            className="card-custom mx-3 my-2 py-2"
            style={{ width: "22rem" }}
          >
            <Card.Body>
              <h1>
                <i class="bi bi-mortarboard"></i>
              </h1>
              <Card.Title>
                <h4>Scholarships/Hackathons</h4>
              </Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                Rewards for excellence and achievements
              </Card.Subtitle>
              <Card.Text>
                We celebrate innovation and talent by organizing scholarships
                and hackathons, offering tech enthusiasts the platform to
                showcase their skills, collaborate, and turn their ideas into
                reality. These events spark creativity and foster a spirit of
                healthy competition.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card
            className="card-custom mx-3 my-2 py-2"
            style={{ width: "22rem" }}
          >
            <Card.Body>
              <h1>
                <i class="bi bi-cash-stack"></i>
              </h1>
              <Card.Title>
                <h4>Funding</h4>
              </Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                Fueling visionary endeavors
              </Card.Subtitle>
              <Card.Text>
                We connect brilliant minds with the resources needed to bring
                their innovative projects to life. By providing funding support
                and access to potential investors, we help turn groundbreaking
                ideas into viable, world-changing solutions.
              </Card.Text>
            </Card.Body>
          </Card>
        </Row>
      </Container>
      <Container>
        <h1 className="text-center whatwedo">
          Frequently Asked Questions (FAQ)
        </h1>
        <Row className=" my-3 py-3 mx-2">
          <Col md="6" sm="2">
            <img
              src="images/questionmark.png"
              className="img-fluid"
              alt="question mark"
            />
          </Col>
          <Col>
            <Accordion>
              {faqs.map((faq, index) => (
                <FAQ key={index} question={faq.question} answer={faq.answer} />
              ))}
            </Accordion>
          </Col>
        </Row>
      </Container>
      <FooterSection darkMode={darkMode} />
    </div>
  );
}

export default HomePage;
