import React, { useState, useEffect, useContext } from "react";
import { Col, Row, Container } from "react-bootstrap";
import NavbarSection from "./NavbarSection";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import FooterSection from "./FoooterSection";
import ThemeContext from "./Themes/ThemeContext";
import "./StylingArena/OurFounders.css";


const API_TOKEN = '6ec7a9a631e5b80d056e214795046f9e7335d3ff'
//const API_TOKEN = process.env.REACT_APP_API_TOKEN;
function OurFounders() {
  const { darkMode, toggleDarkMode } = useContext(ThemeContext);
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await fetch(
          "https://backend.techhive.ng/api/founders",
          {
            headers: {
              Authorization: `Token ${API_TOKEN}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setTeams(data.results);
      } catch (error) {
        console.error("Error fetching teams:", error);
      }
    };
    fetchTeams();
  }, []);

  return (
    <div className={` ${darkMode ? "dark-mode" : ""}`}>
      <NavbarSection onToggle={toggleDarkMode} />
      <section>
        <h3 className="text-center elitehead my-3 py-3">
          The Minds Behind Tech Hive
        </h3>
        <p className="text-left my-3 mx-5 ">
          Explore the world of Tech Hive and meet the visionaries behind its
          creation. This wasn't just a stroke of luck; it was born from
          innovation, passion, and unwavering dedication. Join us as we uncover
          the stories, inspirations, and challenges of Tech Hive's founders.
          Their legacy lives on in Tech Hive's culture of pushing boundaries and
          redefining excellence. Welcome to their story, welcome to Tech Hive.
        </p>
      </section>

      <Container></Container>

      <section className="image-fluid py-3 my-3">
        <h1 className="text-center hackathonhead">
          <h2>Meet Our Founders</h2>
        </h1>
        <Container>
          <Row className="founder-container">
            {teams.map((team) => (
              <div className={` ${darkMode ? "founder-card" : "founder-cardlight"}`}>
                <div className="center-div">
                  <div className={` ${darkMode ? "occupation" : "occupationlight"}`}>{team.occupation}</div>
                </div>

                <div className="center-div">
                  <img src={team.image} className="profile-pic" />
                </div>

                <hr />
                <div class="left-div">
                  <h4 className="name">{team.fullname}</h4>
                </div>

                <div className="left-div achievements">{team.about}</div>
                <hr />

                <div className="tech-div left-div">
                  {team.tech_stack.map((tech) => (
                    <Badge bg={` ${darkMode ? "info techs" : "info techslight"}`}>{tech.stack}</Badge>
                  ))}
                </div>

                <div>
                  <a href={team.portfolio} target="_blank" className="center-div links">
                    <Button className="site-btn  btn btn-warning">View Portfolio</Button>
                  </a>
                </div>
              </div>
            ))}
          </Row>
        </Container>
      </section>
      <FooterSection darkMode={darkMode} />
    </div>
  );
}

export default OurFounders;
