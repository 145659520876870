import React, { useState, useEffect, useContext } from "react";
import { Table, Button, Modal } from "react-bootstrap";
// import {useNavigate} from 'react-router-dom'
import NavbarSection from "./NavbarSection";
import FooterSection from "./FoooterSection";
import ThemeContext from './Themes/ThemeContext';
import './StylingArena/Careers.css';

function formatDate(dateString) {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "short",
  };
  const date = new Date(dateString);
  return date.toLocaleString("en-US", options);
}
const API_TOKEN = process.env.REACT_APP_API_TOKEN;
function Careers() {
  const { darkMode, toggleDarkMode } = useContext(ThemeContext);
  const [careers, setCareers] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [showModal, setShowModal] = useState(false);
  // const token = localStorage.getItem("token");
  // let navigate = useNavigate(); 

  // const handleAuth = () => {
  //   // Redirect to the login page
  //   navigate('/login');
  // };
  useEffect(() => {
    async function fetchCareers() {
      try {
        const response = await fetch("https://backend.techhive.ng/api/jobs", {
          headers: {
            Authorization: `Token ${API_TOKEN}`,
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        setCareers(data.results);

      } catch (error) {
        console.error("Error fetching careers:", error);
      }
    }
    fetchCareers();
  }, []);
  const handleShowModal = (job) => {
    setSelectedJob(job);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  function formatText(text) {
    return text.split('\n').map((item, key) => {
      return <span key={key}>{item}<br/></span>
    })
  }
  return (
    <div className={`  ${darkMode ? 'dark-mode' : ''}`}>
      <NavbarSection onToggle={toggleDarkMode}/>
      <section className=" m-3 p-3">
        <h1 className="text-center careerhead">Looking For a Job?</h1>
        <p>
          Welcome to the Jobs and Careers section of Tech Hive! Our mission is
          to provide a thriving platform where tech enthusiasts, professionals,
          and companies can come together to share knowledge, collaborate, and
          discover exciting career opportunities.
        </p>
        <p>
          Here at TechHive, we believe in the power of technology to transform
          the world, and we're dedicated to fostering a community that supports
          growth and innovation in the industry. Our Jobs and Careers section
          connects talented individuals with top tech companies and startups
          looking for skilled professionals to join their teams.
        </p>
        <p>
          Whether you're a software developer, data scientist, UX/UI designer,
          or digital marketer, our diverse job listings cover a wide range of
          roles and skill sets. Explore exciting opportunities in industries
          such as artificial intelligence, cybersecurity, blockchain, virtual
          reality, and more.
        </p>
      </section>
      <section className=" m-3 p-3">
        <h1 className="text-center careerhead">Open Roles</h1>
        <p>
          Join TechHive today and discover the perfect job opportunity to
          advance your career in the ever-evolving world of technology.
          Together, we can shape the future and drive innovation. Happy job
          hunting!
        </p>
      </section>
      {careers.length === 0 ? (
        <p className="text-center">
          Sorry, there are no open roles at the moment. Please check again
          later.
        </p>
      ) : (
        <div className=" m-3 p-3">
<Table
            className={`text-center  ${
              darkMode ? "dark-table-header" : ""
            }`}
            striped
            bordered
            hover
            responsive
          >
            <thead>
              <tr>
                <th>Role</th>
                <th>Company</th>
                <th>Location</th>
                <th>Job Type</th>
                <th>Action/Details</th>
              </tr>
            </thead>
            <tbody>
              {careers.map((career) => (
                <tr
                  key={career.id}
                  className={`${darkMode ? "dark-table-row" : ""}`}
                >
                  <td>{career.title}</td>
                  <td>{career.company}</td>
                  <td>{career.location}</td>
                  <td>{career.job_type}</td>
                  <td>
                  <Button
                                 variant="warning"
                                 onClick={() => handleShowModal(career)}
                               >
                                 More Details
                               </Button>

                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {selectedJob && (
            <Modal show={showModal} onHide={handleCloseModal}>
              <Modal.Header className="bg-warning" closeButton>
                <Modal.Title>{selectedJob.title}</Modal.Title>
              </Modal.Header>
              <Modal.Body className={` modal-body-custom ${darkMode ? 'dark-mode' : ''}`}>
                <h5>Company</h5>
                <p>{selectedJob.company}</p>
                <h5>Location</h5>
                <p>
                  <i class="bi bi-geo-alt"></i>
                  {selectedJob.location}
                </p>
                <h5>Job Type</h5>
                <p>{selectedJob.job_type}</p>
                <h5>Description</h5>
                <p>{formatText(selectedJob.description)}</p>
                <h5>Salary Range</h5>
                <p>{selectedJob.salary}</p>
                <h5>Date Posted</h5>
                <p>{formatDate(selectedJob.date_posted)}</p>
                <h5>How to Apply</h5>
                <p>{formatText(selectedJob.how_to_apply)}</p>
              </Modal.Body>
              <Modal.Footer className="bg-warning">
                <Button variant="secondary" onClick={handleCloseModal}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </div>
      )}
      <FooterSection darkMode={darkMode}/>
    </div>
  );
}

export default Careers;
