import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Row, Container, Col } from 'react-bootstrap';
import {Puff} from 'react-loader-spinner';
import NavbarSection from "./NavbarSection";
import FooterSection from "./FoooterSection";
import ThemeContext from './Themes/ThemeContext';
import SearchBar from './SearchBar';
import './StylingArena/Bloglist.css'


const API_TOKEN = process.env.REACT_APP_API_TOKEN;
const BlogList = () => {
    const { darkMode, toggleDarkMode } = useContext(ThemeContext);
    const [articles, setArticles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');

  
    useEffect(() => {
        fetchArticles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
    useEffect(() => {
        if (searchQuery !== '') {
          fetchArticles(searchQuery);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [searchQuery]);
      
    
      const fetchArticles = async (searchTerm = '') => {
        try {
          const searchURL = searchTerm
            ? `https://backend.techhive.ng/api/articles/?search=${searchTerm}`
            : `https://backend.techhive.ng/api/articles/`;
          const response = await fetch(searchURL, {
            headers: {
              'Authorization': `Token ${API_TOKEN}`,
              'Content-Type': 'application/json',
            },
          });
          const data = await response.json();
          console.log('searchURL',searchURL)
           console.log('Fetched articles:', data); // Keep this line to inspect the data
           console.log('Articles count:', data.count); // 
          setArticles(data.results);
          setIsLoading(false);
        } catch (error) {
          console.error('Error fetching articles data:', error);
        }
      };
    
      const handleSearch = (query) => {
        setSearchQuery(query);
      };
    
      if (isLoading) {
        return (
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
            <Puff
               type="Puff"
               color="#DAA520"
               height={100}
               width={100}
               timeout={3000} //3 secs
            />
          </div>
        );
      }
    return (
        <div className={`  ${darkMode ? 'dark-mode' : ''}`}>
        <NavbarSection onToggle={toggleDarkMode}/>
        <Container>

                <Row className="py-3 my-3">
                <Col lg="8" md="6" sm="12" className="py-3 my-3">

                <h1>Blog Articles</h1>
                {articles.length === 0 && searchQuery === '' ? (
                  <div>
                    <p>Loading...</p>
                  </div>
                ) : articles.length > 0 ? (
                  <div >
                    {articles.map((article) => (
                        <div className="allbloglist mx-2" key={article.id }>
                          <div className='mx-2'>

                          <img src={article.image} alt={`An visual describing the topic- ${article.title} `} className='img-fluid bloglistimage' />
                          </div>
                          <div>

                        <Link className='bloglistlink' to={`/blog/${article.slug}`}><h3> <b> {article.title}</b></h3></Link>
                        <p>{article.meta_description}...<Link className='bloglistlink' to={`/${article.slug}`}><h6> <b> Read More</b></h6></Link></p>
                          </div>
                          <hr />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div>
                    <p>Sorry, nothing found.</p>
                  </div>
                )}
                </Col>
                <Col className="py-3 my-3">
                  <h4>Looking For Something?</h4>
                <SearchBar onSearch={handleSearch} />

                <div  className="py-3 my-3">
                  
                <h4>Follow Us</h4>
            <p>Stay connected to the hive!!</p>
            <div className="social-icons justify-content-center">
      <a href="https://www.linkedin.com/company/techhiveng/" target="_blank" rel="noopener noreferrer" className="social-icon mx-2">
        <i className="bi bi-linkedin"></i>
      </a>
      <a href="https://twitter.com/techhivenig" target="_blank" rel="noopener noreferrer" className="social-icon mx-2">
        <i className="bi bi-twitter"></i>
      </a>
      <a href="https://www.facebook.com/techhiveng" target="_blank" rel="noopener noreferrer" className="social-icon mx-2">
        <i className="bi bi-facebook"></i>
      </a>
      <a href="https://www.youtube.com/@techhiveng" target="_blank" rel="noopener noreferrer" className="social-icon mx-2">
        <i className="bi bi-youtube"></i>
      </a>
      <a href="https://www.instagram.com/techhivenig" target="_blank" rel="noopener noreferrer" className="social-icon mx-2">
        <i className="bi bi-instagram"></i>
      </a>
      <a href="https://chat.whatsapp.com/H1J8F7Ps2pTHFGXjyddAwg" target="_blank" rel="noopener noreferrer" className="social-icon mx-2">
        <i className="bi bi-whatsapp"></i>
      </a>
    </div>
                </div>
                </Col>
        </Row>
         
        </Container>
        <FooterSection darkMode={darkMode}/>
      </div>
    );
  };
  
  export default BlogList;