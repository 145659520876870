import React, {  useContext } from "react";
import NavbarSection from "./NavbarSection";
import FooterSection from "./FoooterSection";
import ThemeContext from './Themes/ThemeContext';
import './StylingArena/About.css'

function About() {
    const { darkMode, toggleDarkMode } = useContext(ThemeContext);
  return (
    <div className={`  ${darkMode ? 'dark-mode' : ''}`}>
        <NavbarSection onToggle={toggleDarkMode}/>
        <section className="aboutsection">
            <h1 className="abouthead my-3 py-3 text-center">About TechHive</h1>
            <p>TechHive is a vibrant community of tech enthusiasts, professionals, and learners who are passionate about technology and its endless possibilities.</p>
            <p>Our mission is to create an inclusive and collaborative space for people who want to learn, grow and innovate in the field of technology. We offer a range of services and programs to help our members achieve their goals, whether they are just starting out in the industry or looking to take their skills to the next level.</p>
            <p>We believe that everyone has the potential to be a tech leader and to make a positive impact on the world. That's why we provide access to scholarships, hackathons, articles and blog sections, community classes, and funding for innovative projects. Our goal is to help members connect, learn, and collaborate to achieve their dreams and make a difference.</p>
            <p>Our community is made up of individuals from diverse backgrounds and experiences. We celebrate this diversity and believe that it makes our community stronger and more innovative. We welcome anyone who shares our passion for technology and wants to be a part of a supportive and inspiring community.</p>
            <p>Thank you for being a part of TechHive, and we look forward to growing and innovating together.</p>
        </section>
        <FooterSection darkMode={darkMode}/>
        </div>
  )
}

export default About