import React, { useState } from 'react';
import './StylingArena/ScrollToTopButton.css';

const ScrollToTopButton = () => {
  const [visible, setVisible] = useState(false)

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300){
      setVisible(true)
    } 
    else if (scrolled <= 300){
      setVisible(false)
    }
  };

  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
    });
  };

  window.addEventListener('scroll', toggleVisible);

  return (
    <>
    <i className="bi bi-arrow-up-circle scroll-to-top" onClick={scrollToTop} style={{display: visible ? 'inline' : 'none'}} ></i>
    </>

  );
}

export default ScrollToTopButton;
