import React from 'react'
import { Link } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap'
import NewsletterForm from './Forms/NewsletterForm'
import "./StylingArena/FooterSection.css"

const nigerianFlagEmoji = '\u{1F1F3}\u{1F1EC}';
function FooterSection({ darkMode }) {
  return (
    <footer className={`footer py-3 ${darkMode ? 'dark-mode' : ''}`}>
        <Container >

          <Row>
            <Col sm="3" className='my-3 py-3'>
            <div>
              <h5>About Tech Hive</h5>
              <ul>

                <li><Link to={'/about'} className='footerlinks'>About</Link></li>
                <li><Link to={'/blog'} className='footerlinks'>Blog</Link></li>
                <li><Link to={'/careers'} className='footerlinks'>Careers</Link></li>
                <li><Link to={'/contact'} className='footerlinks'>Contact Us</Link></li>
                <li><Link to={'/sponsors'} className='footerlinks'>Sponsor</Link></li>
                <li><Link to={'/our-founders'} className='footerlinks'>Our Founders</Link></li>
              </ul>
            </div>
            <div>
              <h5>Legal</h5>
              <ul>
                <li><Link to={'/privacy-policy'}className='footerlinks'>Privacy Policy</Link></li>
                <li><Link to={'/terms-of-service'} className='footerlinks'>Terms of Service</Link></li>
              </ul>
            </div>
            </Col>
            <Col className='my-3 py-3' sm="3">
            <h5>Social Links</h5>
            <p>Stay connected to the hive!!</p>
            <div className="social-icons justify-content-center">
      <a href="https://www.linkedin.com/company/techhiveng/" target="_blank" rel="noopener noreferrer" className="social-icon mx-2">
        <i className="bi bi-linkedin"></i>
      </a>
      <a href="https://twitter.com/techhivenig" target="_blank" rel="noopener noreferrer" className="social-icon mx-2">
        <i className="bi bi-twitter"></i>
      </a>
      <a href="https://www.facebook.com/techhiveng" target="_blank" rel="noopener noreferrer" className="social-icon mx-2">
        <i className="bi bi-facebook"></i>
      </a>
      <a href="https://www.youtube.com/@techhiveng" target="_blank" rel="noopener noreferrer" className="social-icon mx-2">
        <i className="bi bi-youtube"></i>
      </a>
      <a href="https://www.instagram.com/techhivenig" target="_blank" rel="noopener noreferrer" className="social-icon mx-2">
        <i className="bi bi-instagram"></i>
      </a>
      <a href="https://chat.whatsapp.com/H1J8F7Ps2pTHFGXjyddAwg" target="_blank" rel="noopener noreferrer" className="social-icon mx-2">
        <i className="bi bi-whatsapp"></i>
      </a>
    </div>
            </Col>
            <Col className='my-3 py-3' sm="3">
            <NewsletterForm/>
            </Col>
            <Col className='my-3 py-3'sm="3">
            <img src="/images/techhivelogo.png" alt="techhive" className="image-fluid techhivelogo" />
            <p className='text-info'>Email- info@techhive.ng</p>
            </Col>
          </Row>
        <Row>
            <Col  className="text-center py-3 " >
               <p>TechHive {nigerianFlagEmoji} © 2023  | All Rights Reserved</p>
               <p className='designedbycol'>Developed With 💓 by &copy; <a href="https://justrobin.dev" target='blank' className='designedby'>Robin Okwanma</a></p>
            </Col>
        </Row>
    </Container>
    </footer>
  )
}

export default FooterSection