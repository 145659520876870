import React, {  useContext } from "react";
import NavbarSection from "./NavbarSection";
import FooterSection from "./FoooterSection";
import ThemeContext from './Themes/ThemeContext';

import './StylingArena/TermsOfService.css'

function TermsOfService() {
    const { darkMode, toggleDarkMode } = useContext(ThemeContext);
  return (
    <div className={`  ${darkMode ? 'dark-mode' : ''}`}><NavbarSection onToggle={toggleDarkMode}/>
           <section className="tossection">
        <h1 className="text-center">Terms of Service</h1>
        <p>
        Welcome to TechHive, a community of tech enthusiasts who come together to learn, share knowledge and collaborate on innovative projects. By using the TechHive website (techhive.ng), you agree to these Terms of Service.
        </p>
        <h3>User Conduct</h3>
        <p>
        As a user of the TechHive platform, you agree to:
        </p>
        <ul>
            <li>Respect other users of the platform and refrain from harassing, intimidating, or threatening them in any way.</li>
            <li>Use the platform only for lawful purposes and not engage in any activity that violates any local, national or international laws.</li>
            <li>Not distribute or post any content that is offensive, harmful, or infringes on any third-party rights.</li>
            <li>Not attempt to gain unauthorized access to the platform or interfere with the security features of the platform.</li>
        </ul>
        <h3>
        User Content
        </h3>
        <p>
        As a user of the TechHive platform, you retain ownership of any content that you post on the platform. However, by posting content on the platform, you grant TechHive a non-exclusive, royalty-free, worldwide license to use, copy, reproduce, and modify your content for the purpose of operating the platform and promoting TechHive.
        </p>
        <p>You are solely responsible for the content that you post on the platform, and you agree not to post any content that is:</p>
        <ul>
            <li>False, inaccurate, or misleading</li>
            <li>Infringing on any third-party rights</li>
            <li>Offensive, harmful, or inappropriate</li>
            <li>In violation of any laws or regulations</li>
        </ul>
        <p>TechHive reserves the right to remove any content that violates these Terms of Service or is otherwise deemed inappropriate by TechHive.</p>
        <h3>
        Intellectual Property
        </h3>
        <p>
        TechHive and its logos, trademarks, and service marks are the property of TechHive and are protected by intellectual property laws. You may not use TechHive's intellectual property without TechHive's prior written consent.
        </p>
        <h3>
        Disclaimer of Warranties
        </h3>
        <p>The TechHive platform is provided on an "as is" and "as available" basis. TechHive makes no representations or warranties of any kind, express or implied, as to the operation of the platform or the information, content, materials, or products included on the platform. You expressly agree that your use of the platform is at your sole risk.</p>
        <h3>
        Limitation of Liability
        </h3>
        <p>
        TechHive shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of the platform, including, but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses.
        </p>
        <h3>Changes to the Terms of Service</h3>
        <p>
        TechHive may modify these Terms of Service at any time, and such modifications shall be effective immediately upon posting of the modified Terms of Service. Your continued use of the platform after the posting of the modified Terms of Service shall be deemed your acceptance of the modified Terms of Service.
        </p>
        <h3>Termination</h3>
        <p>
        TechHive reserves the right to terminate your use of the platform at any time for any reason, including, but not limited to, violation of these Terms of Service or engaging in conduct that TechHive deems inappropriate.
        </p>
        <h3>
        Governing Law
        </h3>
        <p>These Terms of Service shall be governed by and construed in accordance with the laws of Nigeria. Any dispute arising out of or in connection with these Terms of Service shall be subject to the exclusive jurisdiction of the Nigerian courts.</p>
        <h3>Contact Us</h3>
        <p>If you have any questions about  about these Terms of Service, please contact us at </p>
        <p><b>TechHive</b>
            <p>Email: info@techhive.ng</p>
        </p>
        <p><b>This document was last updated on May 10, 2023.</b></p>
        
       </section>
    <FooterSection darkMode={darkMode}/>
    </div>
  )
}

export default TermsOfService