import React, { useState, useContext } from 'react';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate, useParams, Link } from 'react-router-dom';
import FooterSection from "./FoooterSection";
import ThemeContext from './Themes/ThemeContext';
import './StylingArena/PasswordReset.css'


// const API_TOKEN = process.env.REACT_APP_API_TOKEN;
const PasswordReset = () => {
    // eslint-disable-next-line
    const { darkMode, toggleDarkMode } = useContext(ThemeContext);
    const { token } = useParams();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        password: "",
        password_confirm: "",
    });
    const [message, setMessage] = useState('');
      // eslint-disable-next-line
    const [email, setEmail] = useState(localStorage.getItem('resetEmail') || '');
         // eslint-disable-next-line
    const [resetEmail, setResetEmail] = useState('');

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
            // Check if the passwords match
    if (formData.password !== formData.password_confirm) {
        setMessage('Error: Passwords do not match.');
        return;
    }
        try {
            await axios.post(`https://backend.techhive.ng/api/password-reset/${token}/`, {
                password: formData.password,
                email: email,
                password_confirm: formData.password_confirm
            }, {
               headers: {
                   'Content-Type': 'application/json',
               },
            });
            setMessage('Password has been reset successfully. You can now log in with your new password.');
            localStorage.removeItem('resetEmail', resetEmail);
            setTimeout(() => {
                navigate("/login");
            }, 2000);
        } catch (error) {
            setMessage('Error!!: Something went wrong. Please try again or contact support.');
            // console.log(error)
        }
    };

    return (
        <div className={`  ${darkMode ? 'dark-mode' : ''}`}>
                  <div className="text-center py-2">
      <Link to={"/"} >
        <img src="/images/techhivelogo.png" alt="Techhive" className="image-fluid techhivelogo" />
              </Link>
      </div>
            <section className='resetformsection text-center'>

            <h3 className='text-center py-3 my-2 resetheader'>Reset Password</h3>
            <Form onSubmit={handleSubmit}>
                <Form.Label>
                    New Password
                    <Form.Control
                        type="password"
                        name="password"
                        placeholder="New Password"
                        onChange={handleChange}
                        required
                    />
                </Form.Label>
                <br />
                <Form.Label>
                    Confirm New Password
                    <Form.Control
                        type="password"
                        name="password_confirm"
                        placeholder="Confirm New Password"
                        onChange={handleChange}
                        required
                    />
                </Form.Label>
                <br />
                <Button variant="warning" type="submit">
                    Reset Password
                </Button>
            </Form>
            {message && <p>{message}</p>}
            </section>
            <FooterSection darkMode={darkMode}/>
        </div>
    );
};

export default PasswordReset;
