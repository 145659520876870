import React, { useState, useContext, useEffect } from "react";
import NavbarSection from "./NavbarSection";
import FooterSection from "./FoooterSection";
// import { Button } from 'react-bootstrap';
import JoinForm from "./Forms/JoinForm";
import { useNavigate } from "react-router-dom";
import ThemeContext from './Themes/ThemeContext';
import "./StylingArena/JoinUs.css";

// function formatDate(dateString) {
//   const options = {
//     year: 'numeric',
//     month: 'long',
//     day: 'numeric',
//     hour: '2-digit',
//     minute: '2-digit',
//     timeZoneName: 'short'
//   };
//   const date = new Date(dateString);
//   return date.toLocaleString('en-US', options);
// }


// const API_TOKEN = process.env.REACT_APP_API_TOKEN;
function JoinUs() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const { darkMode, toggleDarkMode } = useContext(ThemeContext);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/login");
    }
  }, [navigate]);
  return (
    <div className={`joinsecimg py-3 ${darkMode ? 'dark-mode' : ''}`}>
      <NavbarSection onToggle={toggleDarkMode} />
      <div className="joindiv">
        <h3 className="text-center joinhead">Join The Hive!</h3>
        <p className="text-center">Join the fastest growing Tech community in Nigeria!</p>
        <section className="m-3 py-3">
          <h4>Benefits of Joining TechHive Community:</h4>
          <ul>
            <li>Expectations of TechHive Community Members:</li>
            <li>Participation in hackathons and community classes</li>
            <li>Opportunity to network with like-minded individuals in the tech industry</li>
            <li>Access to a community of tech enthusiasts to exchange ideas and knowledge</li>
            <li>Access to the latest articles and blog posts on industry trends and best practices</li>
          </ul>
          <h4>Expectations of TechHive Community Members:</h4>
          <ul>
            <li>Access to the latest articles and blog posts on industry trends and best practices</li>
            <li>Adherence to community guidelines and values</li>
            <li>Willingness to share knowledge and collaborate with other members</li>
            <li>Active participation in community activities and discussions</li>
            <li>Willingness to provide feedback and suggestions to help improve the community</li>
          </ul>
        </section>
        <div className="text-center my-3 py-3">
          <p>Join TechHive Community today and start exploring the benefits of being part of a thriving tech community!</p>

      {/* <Button  variant="warning" onClick={handleShow}>
      Click Here To Join!
    </Button> */}
        </div>
    <br />
    <div className="text-center">

    <h5 >We're Social! Stay Connected With the Hive🐝</h5>
    <div className="social-icons justify-content-center">
      <a href="https://www.linkedin.com/company/techhiveng/" target="_blank" rel="noopener noreferrer" className="social-icon mx-2">
        <i className="bi bi-linkedin"></i>
      </a>
      <a href="https://twitter.com/techhivenig" target="_blank" rel="noopener noreferrer" className="social-icon mx-2">
        <i className="bi bi-twitter"></i>
      </a>
      <a href="https://www.facebook.com/techhiveng" target="_blank" rel="noopener noreferrer" className="social-icon mx-2">
        <i className="bi bi-facebook"></i>
      </a>
      <a href="https://www.youtube.com/@techhiveng" target="_blank" rel="noopener noreferrer" className="social-icon mx-2">
        <i className="bi bi-youtube"></i>
      </a>
      <a href="https://www.instagram.com/techhivenig" target="_blank" rel="noopener noreferrer" className="social-icon mx-2">
        <i className="bi bi-instagram"></i>
      </a>
      <a href="https://chat.whatsapp.com/H1J8F7Ps2pTHFGXjyddAwg" target="_blank" rel="noopener noreferrer" className="social-icon mx-2">
        <i className="bi bi-whatsapp"></i>
      </a>
    </div>
    </div>
      </div>
      <JoinForm show={show} handleClose={handleClose} />
      <FooterSection darkMode={darkMode}/>
    </div>
  );
}

export default JoinUs;
