import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import NavbarSection from "./NavbarSection";
import FooterSection from "./FoooterSection";
import MovingComponent from "react-moving-text";
import ThemeContext from "./Themes/ThemeContext";
import "./StylingArena/Sponsors.css";

function Sponsors() {
  const { darkMode, toggleDarkMode } = useContext(ThemeContext);

  return (
    <div className={`  ${darkMode ? "dark-mode" : ""}`}>
      <NavbarSection onToggle={toggleDarkMode} />
      <section className="my-3 py-3 sponsorshipsect">
        <MovingComponent
          type="popIn"
          className="techhiveaim"
          duration="1000ms"
          delay="0s"
          direction="normal"
          timing="ease-in"
          iteration="2"
          fillMode="none"
        >
          <p className="text-center">Sponsorship at Tech Hive</p>
        </MovingComponent>

        <p className="sponsorintro ">
          Tech Hive is a vibrant, ever-growing community of tech enthusiasts,
          developers, and innovators. Our mission is to foster collaboration,
          learning, and growth in the tech industry. We are proud to showcase
          the organizations and individuals who have partnered with us to make
          our community stronger. Join our dynamic network of sponsors and help
          us drive the future of technology!
        </p>
        <hr className="mx-5 px-3 text-warning sponsorline" />
      </section>
      <section className="sponsorimg sponsorshipsect">
        <h1 className="text-center techhiveaim">Meet Our Sponsors</h1>
        <p className="sponsorintro2 text-center">
          Our Sponsors Past and Present{" "}
        </p>

        <div className="sponsor-cont">
          <div className={`  ${darkMode ? "sponsor-hold" : "sponsor-holdlight"}`}>
            <img
              src="/images/bruteforceacademysponsor.jpg"
              alt=""
              class="sponsor-img"
            />
          </div>

          <div className={`  ${darkMode ? "sponsor-hold" : "sponsor-holdlight"}`}>
            <img src="/images/cyberngsponsor.png" alt="" class="sponsor-img" />
          </div>

          <div className={`  ${darkMode ? "sponsor-hold" : "sponsor-holdlight"}`}>
            <img
              src="/images/techrantersponsor.png"
              alt=""
              class="sponsor-img"
            />
          </div>

          <div className={`  ${darkMode ? "sponsor-hold" : "sponsor-holdlight"}`}>
            <img
              src="/images/cyberdatahubsponsor.png"
              alt=""
              class="sponsor-img"
            />
          </div>
        </div>

        <hr className="mx-5 px-3 text-warning sponsorline" />
      </section>
      <section className="my-3 py-3 sponsorshipsect">
        <h1 className="text-center techhiveaim my-3 py-3">Sponsor Us?</h1>
        <div>
          <p className="sponsortext text-center">
            Are you interested in becoming a sponsor for Tech Hive? We would
            love to have you on board! <br />
            You coud host a hackathon, class or event. Hire from our developer
            base or just support our work. <br /> <br /> Do you have something
            else in Mind? Reach out to us us at partnerships@techhive.ng
          </p>
          {/* <p className="sponsortext">By Sponsoring in our Tech community, you can;</p>
          <ul className="">
            <li>
              Connect with a diverse and talented network of tech professionals
            </li>
            <li>Increase your brand visibility within the tech community</li>
            <li>
              Support the growth and development of technology enthusiasts and
              professionals in Nigeria and Africa
            </li>
          </ul> */}
          {/* <p className="sponsortext my-3 py-3">
          Our sponsorship packages offer a variety of benefits and opportunities
          tailored to your needs. To learn more about our sponsorship options,
          please contact us at sponsor@techhive.ng
          </p> */}
        </div>
      </section>
      <FooterSection darkMode={darkMode} />
    </div>
  );
}

export default Sponsors;
