import React, { useState } from 'react';
import {Form, Button} from 'react-bootstrap';

const SearchBar = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleChange = (e) => {
    console.log('handleChange:', e.target.value); 
    setSearchTerm(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('handleSubmit:', searchTerm);  
    onSearch(searchTerm);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Control
        type="search"
        value={searchTerm}
        onChange={handleChange}
        placeholder="Search for articles..."
      />
      <Button type="submit" variant='warning' className='py-2 my-2'>Search</Button>
    </Form>
  );
};

export default SearchBar;
