import React, {useContext, useState, useEffect} from 'react'
import ThemeContext from './Themes/ThemeContext';
import { Row, Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import Sidebar from './Sidebar'
import "./StylingArena/Community.css"

const API_TOKEN = process.env.REACT_APP_API_TOKEN;
function Community() {
  const { darkMode, toggleDarkMode } = useContext(ThemeContext);
  const [community, setCommunity] = useState({ results: [] });
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const location = useLocation();
  
  useEffect(() => {
    async function fetchCommunity() {
      try {
        const response = await fetch("https://backend.techhive.ng/api/community", {
          headers: {
            Authorization: `Token ${API_TOKEN}`,
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        setCommunity(data)

      } catch (error) {
        console.error("Error fetching communities:", error);
      }
    }
    fetchCommunity();
  }, []);

  useEffect(() => {
    setSidebarOpen(false);
  }, [location]);

  return (
    <div className={`  ${darkMode ? 'dark-mode' : ''}`}  style={{ display: "flex" }}>
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} onToggle={toggleDarkMode}/>
      <div className='communitydivright'>
      <nav>
  <div className="bg-warning p-1 sticky-top " style={{ display: 'flex',  justifyContent: 'space-between'}}>      <h2 onClick={() => setSidebarOpen(!sidebarOpen)}>
        {sidebarOpen ? <i className="bi bi-list p-2"></i> : <i className="bi bi-list p-2"></i>}
      </h2>
      <div className="my-1">
              {/* <button className="btn btn-warning" onClick={handleLogoutx}>
              <FaArrowLeft/> Logout
            </button> */}
              <button className="btn " onClick={toggleDarkMode}>
                {darkMode ? (
                  <h4>
                    <i className="bi bi-sun-fill text-success"></i>
                  </h4>
                ) : (
                  <h4>
                    <i className="bi bi-moon-fill text-black"></i>
                  </h4>
                )}
              </button>
            </div>
      </div>
</nav>
        <div className= "communitycontainerright ">
          <h2 className='text-center my-2 py-2 communtyheader'>Join Our Community</h2>
          <h4 className='text-center my-2 p-2'>We're Everywhere! Join our active community</h4>
          {community.results.map((item, index) => (
            <Row className='m-3 p-3' key={index}>
            <Row className='m-3 p-3'>
              <Col>
              <a href={item.linkedin} target="_blank" rel="noreferrer">
              <img src="/images/linkedintransp.webp" className='communitylinkimg img-fluid' alt="Linkedin" />

              </a>
              </Col>
              <Col>
              <a href={item.facebook} target="_blank" rel="noreferrer">
              <img src="/images/facebooktransp.png" className='communitylinkimg img-fluid' alt="Facebook" />

              </a>
              </Col>
              <Col>
              <a href={item.twitter} target="_blank" rel="noreferrer">
              <img src="/images/twittertransp.png" className='communitylinkimg img-fluid' alt="Twitter" />

              </a>
              </Col>
              <Col>
              <a href={item.slack} target="_blank" rel="noreferrer" >
              <img src="/images/slacktransp.png" className='communitylinkimg img-fluid' alt="Slack" />
                </a>
              </Col>
              </Row>
              <Row className='m-3 p-3'>
              <Col>
              <a href={item.whatsapp} target="_blank" rel="noreferrer">

              <img src="/images/whatsapptransp2.png" className='communitylinkimg img-fluid' alt="Whatsapp1" />
              </a>
              </Col>
              <Col>
              <a href={item.youtube} target="_blank" rel="noreferrer">
              <img src="/images/youtubetransp.avif" className='communitylinkimg img-fluid' alt="Youtube" />
              </a>
              </Col>
              <Col>
              <a href={item.instagram} target="_blank" rel="noreferrer">
              <img src="/images/instagramtransp.png" className='communitylinkimg img-fluid' alt="Instagram" />
              </a>
              </Col>
              <Col>
              <a href={item.whatsapp_community} target="_blank" rel="noreferrer">

              <img src="/images/whatsapptransp2.png" className='communitylinkimg img-fluid' alt="Whatsapp1" />
              </a>
              </Col>
              </Row>

      

            </Row>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Community
