import React, { useState, useEffect, useContext } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import FooterSection from "./FoooterSection";
import ThemeContext from "./Themes/ThemeContext";
import { loadGapiInsideDOM } from "gapi-script";
import "./StylingArena/Login.css";

import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';

// inside your component



const API_TOKEN = process.env.REACT_APP_API_TOKEN;
const Login = () => {
  // eslint-disable-next-line
  const { darkMode, toggleDarkMode } = useContext(ThemeContext);
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  let navigate = useNavigate();

  // Add this useEffect hook
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/community");
    }
  }, [navigate]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleShowResetModal = () => setShowResetModal(true);
  const handleCloseResetModal = () => setShowResetModal(false);
  const handleResetChange = (e) => {
    setResetEmail(e.target.value);
  };

  const handleResetSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://backend.techhive.ng/api/password-reset/",
        { email: resetEmail },
        {
          headers: {
            Authorization: `Token ${API_TOKEN}`,
            "Content-Type": "application/json",
          },
        }
      );
      setMessage(
        "If an account with this email exists, a password reset link has been sent."
      );
      localStorage.setItem("resetEmail", resetEmail);
      handleShow(); // Open the login status modal
      handleCloseResetModal(); // Close the password reset modal
      console.log(response);
    } catch (error) {
      setMessage("Error!!: Something went wrong.");
      handleShow(); // Open the login status modal
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://backend.techhive.ng/api/login/",
        formData,
        {
          headers: {
            Authorization: `Token ${API_TOKEN}`,
            "Content-Type": "application/json",
          },
        }
      );
      // console.log(response)
      if (response.data.token) {
        // Check if token is present
        localStorage.setItem("token", response.data.token); // Save the token to local storage
        setMessage("Login successful");
        handleShow(); // Open the modal
        setTimeout(() => {
          handleClose(); // Close the modal after 2 seconds
          navigate("/community"); // Redirect to dashboard page
        }, 4000);
      }
    } catch (error) {
      setMessage("Error!!: Invalid email or password!!");
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  function responseGoogle(response) {
    // Check for error
    if (response.error) {
      console.error('Google login error:', response.error);  // Log more detailed error information
      setMessage(response.error);
      handleShow();
      return;
    }
  
    // Send the id_token to the backend
    const tokenId = response.tokenId;
    fetch('https://backend.techhive.ng/api/google-login/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({id_token: tokenId}),
    })
    .then((res) => {
      if (!res.ok) {
        // Handle error
        throw new Error('Network response was not ok');
      }
      return res.json();
    })
    .then((data) => {
      // Check if token is present
      if (data.token) {
        // Save the token to local storage
        localStorage.setItem('token', data.token);
        setMessage('Login successful');
        handleShow(); // Open the modal
        setTimeout(() => {
          handleClose(); // Close the modal after 2 seconds
          navigate('/community'); // Redirect to dashboard page
        }, 4000);
      }
    })
    .catch((error) => {
      // Handle error
      setMessage('Error!!: Something went wrong.');
      handleShow(); // Open the login status modal
    });
    
  }

 
    const responseFacebook = (response) => {
      console.log(response);
      // You can access user's public profile and email here
      // Send these details to your backend via fetch/axios
  
      const accessToken = response.accessToken; // Get the access token from the response
  
      // Send the access token to the backend
      fetch('https://backend.techhive.ng/api/facebook-login/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({access_token: accessToken}),
      })
      .then(res => res.json())
      .then(data => {
        // Handle the response from your backend
        console.log(data);
        if (data.token) {
          localStorage.setItem('token', data.token);
          // navigate to another page
        }
      })
      .catch(error => {
        console.error(error);
      });
    }
  
  useEffect(() => {
    (async () => {
      await loadGapiInsideDOM();
    })();
  });
  

  return (
    <div className={`  ${darkMode ? "dark-mode" : ""}`}>
      <div className="text-center py-2">
        <Link to={"/"}>
          <img
            src="/images/techhivelogo.png"
            alt="Techhive"
            className="image-fluid techhivelogo"
          />
        </Link>
      </div>
      <section className="loginformsection">
        <h3 className="text-center py-3 my-2 loginheader">Login</h3>
        <Form onSubmit={handleSubmit} className="loginform text-center">
          <Form.Label>
            Email
            <Form.Control
              type="email"
              name="username"
              placeholder="Your email"
              onChange={handleChange}
              required
            />
          </Form.Label>
          <br />
          <Form.Label>
            Password
            <Form.Control
              type="password"
              name="password"
              placeholder="Password"
              onChange={handleChange}
              required
            />
          </Form.Label>
          <br />
          <button className="btn btn-warning my-2" type="submit">
            Login
          </button>
          <p>OR</p>
          <div className="d-flex flex-column align-items-center">
    <Button className="my-2 btn-danger col-12 col-sm-8 col-md-6 col-lg-4">
        Sign in with Google <i className="bi bi-google"></i>
    </Button>
    <GoogleLogin
    clientId="949102781841-j2a67e7nis3lg582h54k3ppe3pv1k0rs.apps.googleusercontent.com"
    buttonText="Sign in with Google"
    onSuccess={responseGoogle}
    onFailure={responseGoogle}
    cookiePolicy={'single_host_origin'}
/>
<p></p>
<FacebookLogin
      appId="275741228200397" // replace with your app id
      autoLoad={true}
      fields="name,email,picture"
      callback={responseFacebook}
    />
    <Button className="my-2 col-12 col-sm-8 col-md-6 col-lg-4">
        Sign in with Facebook <i className="bi bi-facebook"></i>
    </Button>
</div>

          <br />
          <button
            className="btn btn-link my-2"
            type="button"
            onClick={handleShowResetModal}
          >
            Forgot password?
          </button>
          <p className="py-2 my-2">
            Not a menber Yet? <Link to={"/join-us"}> Join Us</Link>
          </p>
          {message && <p className="errormessage">{message}</p>}
        </Form>
      </section>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Login Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showResetModal} onHide={handleCloseResetModal}>
        <Modal.Header>
          <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleResetSubmit}>
            <Form.Label>
              Email
              <Form.Control
                type="email"
                name="reset_email"
                placeholder="Your email"
                onChange={handleResetChange}
                required
              />
            </Form.Label>
            <br />
            <button className="btn btn-warning my-2" type="submit">
              Reset Password
            </button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseResetModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <FooterSection darkMode={darkMode} />
    </div>
  );
};

export default Login;
