import React, { useState, useEffect, useContext } from "react";
import { Card, Button, Modal } from "react-bootstrap";
import NavbarSection from "./NavbarSection";
import FooterSection from "./FoooterSection";
import ThemeContext from './Themes/ThemeContext'
import "./StylingArena/Events.css"

function formatDate(dateString) {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short'
  };
  const date = new Date(dateString);
  return date.toLocaleString('en-US', options);
}


const API_TOKEN = process.env.REACT_APP_API_TOKEN;
function Events() {
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const { darkMode, toggleDarkMode } = useContext(ThemeContext);
  
  useEffect(() => {
    async function fetchEvents() {
      try {
                const response = await fetch("https://backend.techhive.ng/api/events", {
          headers: {
            'Authorization': `Token ${API_TOKEN}`,
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        const upcoming = data.results.filter(event => new Date(event.datetime) >= new Date());
        const past = data.results.filter(event => new Date(event.datetime) < new Date());
        setUpcomingEvents(upcoming);
        setPastEvents(past);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    }
    
    fetchEvents();
  }, []);
  function handleModal(event = null) {
    setSelectedEvent(event);
    setShowModal(!showModal);
  }
  function formatText(text) {
    return text.split('\n').map((item, key) => {
      return <span key={key}>{item}<br/></span>
    })
  }
function renderEventCard(event) {
  
  return (
    <div key={event.id} className=" my-3 py-3 mx-3">
      <Card className="card-custom" style={{ width: "20rem"}}>
        <Card.Body>
        <Card.Img variant="top" src={event.image} height={200} width={250}/>
          <Card.Title>{event.title}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">{formatDate(event.datetime)}</Card.Subtitle>
          <Card.Text>{formatText(event.description)}</Card.Text>
          <Card.Link className="btn btn-warning" onClick={() => handleModal(event)}>More Details</Card.Link>
          <Card.Link className="btn btn-warning">Register</Card.Link>
        </Card.Body>
      </Card>
    </div>
  );
}

  return (
    <div className={darkMode ? 'dark-mode' : ''}>
      <NavbarSection onToggle={toggleDarkMode}/>
      <h1 className="text-center eventhead my-3 py-3">Upcoming Events</h1>
<div className="text-center my-3 py-3 mx-1 eventcard">
  {upcomingEvents.map(renderEventCard)}
</div>

<h1 className="text-center eventhead">Past Events</h1>
<div className=" my-3 py-3 mx-1 eventcard">
  {pastEvents.map(renderEventCard)}
</div>
<Modal show={showModal} onHide={() => handleModal()}>
        <Modal.Header className="bg-warning" closeButton>
          <Modal.Title>{selectedEvent && selectedEvent.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={` modal-body-custom ${darkMode ? 'dark-mode' : ''}`}>
          <p><strong>Date:</strong> {selectedEvent && formatDate(selectedEvent.datetime)}</p>
          <p><strong>Location:</strong> {selectedEvent && selectedEvent.location}</p>
          <p><strong>Capacity:</strong> {selectedEvent && selectedEvent.capacity}</p>
          <p><strong>Description:</strong> {selectedEvent && formatText(selectedEvent.description)}</p>
          <br />
          <p><strong>Registration Details:</strong> {selectedEvent && formatText(selectedEvent.reg_details)}</p>    
          <p><strong>Agenda:</strong> {selectedEvent && formatText(selectedEvent.agenda)}</p>
          <br />
          <p><strong>Organizers:</strong> {selectedEvent && selectedEvent.organizers}</p>
          {selectedEvent && selectedEvent.speakers && (
  <div>
    <strong>Speakers:</strong>
    {selectedEvent.speakers.map((speaker, index) => (
      <div key={index}>
        <p>{speaker.name}</p>
        <img src={speaker.image} alt="" className="img-fluid" width={200}/>
        <p>{speaker.bio}</p>
        {/* Add other organizer properties if needed */}
      </div>
    ))}
  </div>
)}
          {selectedEvent && selectedEvent.sponsors && (
  <div>
    <strong>Sponsors:</strong>
    {selectedEvent.sponsors.map((sponsor, index) => (
      <div key={index}>
        <p>{sponsor.name}</p>
        <img src={sponsor.image} alt="" className="img-fluid" width={150}/>
        <p>{sponsor.description}</p>
        {/* Add other organizer properties if needed */}
      </div>
    ))}
  </div>
)}
          {/* Add more data from the API response here */}
        </Modal.Body>
        <Modal.Footer className="bg-warning">
          <Button variant="secondary" onClick={() => handleModal()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <FooterSection darkMode={darkMode}/>
    </div>
  );
}

export default Events;

