import React, { useState, useEffect, useContext } from "react";
import { Col, Row, Container, Badge, Button } from "react-bootstrap";
import NavbarSection from "./NavbarSection";
import FooterSection from "./FoooterSection";
import ThemeContext from "./Themes/ThemeContext";
import "./StylingArena/OurElite.css";

const API_TOKEN = process.env.REACT_APP_API_TOKEN;
function OurElite() {
  const { darkMode, toggleDarkMode } = useContext(ThemeContext);
  const [teams, setTeams] = useState([]);
  const [currentMonth, setCurrentMonth] = useState("");
  const [currentYear, setCurrentYear] = useState("");
  // const [selectedTeam, setSelectedTeam] = useState(null);
  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await fetch("https://backend.techhive.ng/api/teams", {
          headers: {
            Authorization: `Token ${API_TOKEN}`,
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        setTeams(data.results);
      } catch (error) {
        console.error("Error fetching teams:", error);
      }
    };
    fetchTeams();
  }, []);

  useEffect(() => {
    const date = new Date();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    setCurrentMonth(month);
    setCurrentYear(year);
  }, []);
  return (
    <div className={` ${darkMode ? "dark-mode" : ""}`}>
      <NavbarSection onToggle={toggleDarkMode} />
      <section>
        <h3 className="text-center elitehead my-3 py-3">Our Monthly Elite Members</h3>
        <p className="text-center">
          Here are some of our best techies in the hive based on their
          productivity, and accomplishments for the month
        </p>
      </section>
      <section className="image-fluid py-3 my-3">
      <h1 className="text-center hackathonhead">
        <h2>
          Elite Members of the Hive for {currentMonth} {currentYear}
        </h2>
      </h1>
      <Container>
          <Row className="founder-container">
            {teams.map((team) => (
              <div className={` ${darkMode ? "founder-card" : "founder-cardlight"}`}>
                <div className="center-div">
                  <div className={` ${darkMode ? "occupation" : "occupationlight"}`}>{team.occupation}</div>
                </div>

                <div className="center-div">
                  <img src={team.image} className=" eliteimage" />
                </div>

                <hr />
                <div class="left-div">
                  <h4 className="name">{team.fullname}</h4>
                </div>

                <div className="left-div achievements">{team.about}</div>
                <hr />

                <div className="tech-div left-div">
                  {team.tech_stack.map((tech) => (
                    <Badge bg={` ${darkMode ? "info techs" : "info techslight"}`}>{tech.stack}</Badge>
                  ))}
                </div>

                <div>
                  <a href={team.portfolio} target="_blank" className="center-div links">
                    <Button className="site-btn  btn btn-warning">View Portfolio</Button>
                  </a>
                </div>
              </div>
            ))}
          </Row>
        </Container>
    </section>
      <FooterSection darkMode={darkMode} />
    </div>
  );
}

export default OurElite;
