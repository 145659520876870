import React, { useState, useContext } from "react";
import axios from "axios";
import { Button, Modal, Form, Alert} from 'react-bootstrap';
import ThemeContext from '../Themes/ThemeContext';
import "../StylingArena/JoinForm.css"

// function generateRandomPassword(length = 8) {
//   const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()';
//   let password = '';

//   for (let i = 0; i < length; i++) {
//     const randomIndex = Math.floor(Math.random() * characters.length);
//     password += characters[randomIndex];
//   }

//   return password;
// }

function JoinForm({ show, handleClose }) {
  // eslint-disable-next-line
  const { darkMode, toggleDarkMode } = useContext(ThemeContext);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [formData, setFormData] = useState({
      email: "",
      first_name: "",
      last_name: "",
      phone_number: "",
      password: "",
      confirm_password: "",
      bio: "",
      state: null,
      address: "",
      occupation: "",
      linkedin: "",
      twitter: "",
      website: null,
      why_join: "",
      referral_source: null,
    });
  
    const Error = ({ message }) => {
      return <div className="error-message">{message}</div>;
    };
  
    const Success = ({ message }) => {
      return <div className="success-message">{message}</div>;
    };
  
    const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
      
    };
    const handleCloseSuccessPopup = () => {
      setShowSuccessPopup(false);
    };
    const validatePasswords = () => {
      if (formData.password !== formData.confirm_password) {
        setError('Passwords do not match');
        return false;
      } else {
        setError(null);
        return true;
      }
    };
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        if (validatePasswords()) {
                  // Generate a random password for the user
        // const password = generateRandomPassword();
        
        //Add the generated password to the form data
        // const newUser = {
        //   ...formData,
        
        // };
          // console.log("Submitting data:", newUser);
          const response = await axios.post(
            "https://backend.techhive.ng/api/register/",
            formData
            );
            console.log(response.data);
            setSuccessMessage("Form submitted successfully!");
            setError(null);
            handleClose();
            setTimeout(() => {
              setShowSuccessPopup(true); // Show the success popup after a delay
            }, 500); // Adjust the delay as needed (in milliseconds)
        } else {
          setError(true);
          // console.log("error");
        }
      } catch (error) {
        console.error(error);
        setError("An error occurred while submitting the form.");
        setSuccessMessage(null);
      }
    };
    
  return (
    <>
    <Modal show={show} onHide={handleClose} >
      <Modal.Header className="bg-warning" closeButton>
        <Modal.Title className="text-center">Join Us</Modal.Title>
      </Modal.Header>
      <Modal.Body className={` modal-body-custom ${darkMode ? 'dark-mode' : ''}`}>
      <Form onSubmit={handleSubmit} className="">
            <Form.Label className="py-2">
             Email*
               <Form.Control
                type="email"
                name="email"
                placeholder="Email"
                onChange={handleChange}
                required
              />
              {/* <span className="error-message">Please enter a valid email.</span> */}
            </Form.Label>
            <Form.Label className="py-2 ">
              First Name*
              <Form.Control
                type="text"
                name="first_name"
                placeholder="First Name"
                onChange={handleChange}
                required
              />
              {/* <span className="error-message">Please enter your first name.</span> */}
            </Form.Label>
            <Form.Label className="py-2">
              Last Name*
              <Form.Control
                type="text"
                name="last_name"
                placeholder="Last Name"
                onChange={handleChange}
                required
              />
        {/* <span className="error-message">Please enter your last name.</span> */}
            </Form.Label>
            <Form.Label className="py-2 ">
              Phone Number*
              <Form.Control
                type="text"
                name="phone_number"
                placeholder="+2348000000000"
                onChange={handleChange}
                required
              />
        {/* <span className="error-message">Please enter a valid phone number.</span> */}
            </Form.Label>
            <Form.Label>
              Password
              <Form.Control
                type="password"
                name="password"
                placeholder="Password"
                onChange={handleChange}
                required
              />
            </Form.Label>
            <Form.Label>
              Confirm Password
              <Form.Control
                type="password"
                name="confirm_password"
                placeholder="Confirm Password"
                onChange={handleChange}
                required
                />
            </Form.Label>
                {error && <p>{error}</p>}
            <Form.Label className="py-2 ">
              Bio*
              <Form.Control
                name="bio"
                as="textarea"
                placeholder="Tell us a bit about Yourself"
                onChange={handleChange}
                required
              ></Form.Control>
            </Form.Label>
            <Form.Label  className="py-2 ">
              State of Origin*
              <Form.Select name="state" onChange={handleChange} required>
                <option value="">Select a state</option>
                <option value="AB" key="AB">
                  Abia
                </option>
                <option value="AD" key="AD">
                  Adamawa
                </option>
                <option value="AK" key="AK">
                  Akwa Ibom
                </option>
                <option value="AN" key="AN">
                  Anambra
                </option>
                <option value="BA" key="BA">
                  Bauchi
                </option>
                <option value="BY" key="BY">
                  Bayelsa
                </option>
                <option value="BE" key="BE">
                  Benue
                </option>
                <option value="BO" key="BO">
                  Borno
                </option>
                <option value="CR" key="CR">
                  Cross River
                </option>
                <option value="DE" key="DE">
                  Delta
                </option>
                <option value="EB" key="EB">
                  Ebonyi
                </option>
                <option value="ED" key="ED">
                  Edo
                </option>
                <option value="EK" key="EK">
                  Ekiti
                </option>
                <option value="EN" key="EN">
                  Enugu
                </option>
                <option value="FC" key="FC">
                  Federal Capital Territory
                </option>
                <option value="GO" key="GO">
                  Gombe
                </option>
                <option value="IM" key="IM">
                  Imo
                </option>
                <option value="JI" key="JI">
                  Jigawa
                </option>
                <option value="KD" key="KD">
                  Kaduna
                </option>
                <option value="KN" key="KN">
                  Kano
                </option>
                <option value="KT" key="KT">
                  Katsina
                </option>
                <option value="KE" key="KE">
                  Kebbi
                </option>
                <option value="KO" key="KO">
                  Kogi
                </option>
                <option value="KW" key="KW">
                  Kwara
                </option>
                <option value="LA" key="LA">
                  Lagos
                </option>
                <option value="NA" key="NA">
                  Nasarawa
                </option>
                <option value="NI" key="NI">
                  Niger
                </option>
                <option value="OG" key="OG">
                  Ogun
                </option>
                <option value="ON" key="ON">
                  Ondo
                </option>
                <option value="OS" key="OS">
                  Osun
                </option>
                <option value="OY" key="OY">
                  Oyo
                </option>
                <option value="PL" key="PL">
                  Plateau
                </option>
                <option value="RI" key="RI">
                  Rivers
                </option>
                <option value="SO" key="SO">
                  Sokoto
                </option>
                <option value="TA" key="TA">
                  Taraba
                </option>
                <option value="YO" key="YO">
                  Yobe
                </option>
                <option value="ZA" key="ZA">
                  Zamfara
                </option>
              </Form.Select>
            </Form.Label>
            <Form.Label  className="py-2 ">
              Address*
              <Form.Control
                type="text"
                name="address"
                placeholder="Address"
                onChange={handleChange}
                required
              />
            </Form.Label>
            <Form.Label  className="py-2 ">
              Occupation*
              <Form.Control
                type="text"
                name="occupation"
                placeholder="Occupation"
                onChange={handleChange}
                required
              />
            </Form.Label>
            <Form.Label  className="py-2 ">
              Linkedin*
              <Form.Control
                type="url"
                name="linkedin"
                placeholder="https://linkedin.com/in/username"
                onChange={handleChange}
                required
              />
            </Form.Label>
            <Form.Label  className="py-2 ">
              Twitter
              <Form.Control
                type="url"
                name="twitter"
                placeholder="https://twitter.com/username"
                onChange={handleChange}
                
              />
            </Form.Label>
            <Form.Label  className="py-2 ">
              Website/Portfolio
              <Form.Control
                type="url"
                name="website"
                placeholder="https://sitename.com"
                onChange={handleChange}
              />
            </Form.Label>
            <Form.Label  className="py-2 ">
              Why Do You Want to Join Us?*
              <Form.Control
                name="why_join"
                as="textarea"
                placeholder="Why Do You Want to Join Us"
                onChange={handleChange}
                required
              ></Form.Control>
            </Form.Label>
            <Form.Label  className="py-2 ">
              How did you hear about TechHive?*
              <Form.Select name="referral_source" onChange={handleChange} required>
                <option value="">Select an option</option>
                <option value="Linkedin" key="Linkedin">
                  Linkedin
                </option>
                <option value="Facebook" key="Facebook">
                  Facebook
                </option>
                <option value="Google" key="Google">
                  Google
                </option>
                <option value="From a Friend" key="From a Friend">
                  From a Friend
                </option>
                <option value="Online Blogs" key="Online Blogs">
                  Online Blogs
                </option>
                <option value="Other" key="Other">
                  Other
                </option>
              </Form.Select>
            </Form.Label>
      {error && <Error message={error} />}
      {successMessage && <Success message={successMessage} />}
          </Form>
      </Modal.Body>
      <Modal.Footer className="bg-warning">
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary"  onClick={handleSubmit}>
          Submit
        </Button>
      </Modal.Footer>

    </Modal>
          {/* Success Popup */}
          <Modal show={showSuccessPopup} onHide={handleCloseSuccessPopup}>
        <Modal.Header className="bg-warning" closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body className={` modal-body-custom ${darkMode ? 'dark-mode' : ''}`} >
          <Alert variant="success">
            The form was submitted successfully! You will be sent an exclusive invite soon to the email you provided.
          </Alert>
        </Modal.Body>
        <Modal.Footer className="bg-warning">
          <Button variant="success" onClick={() => setShowSuccessPopup(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
    
  );
}

export default JoinForm;



// import React, { useState } from "react";
// import axios from "axios";


// function JoinForm() {
//     const [error, setError] = useState(null);
//     const [successMessage, setSuccessMessage] = useState(null);
//     const [formData, setFormData] = useState({
//       email: "",
//       first_name: "",
//       last_name: "",
//       phone_number: "",
//       password: "",
//       confirm_password: "",
//       bio: "",
//       state: null,
//       address: "",
//       occupation: "",
//       linkedin: "",
//       twitter: "",
//       website: "",
//       why_join: "",
//       referral_source: null,
//     });
  
//     const Error = ({ message }) => {
//       return <div className="error-message">{message}</div>;
//     };
  
//     const Success = ({ message }) => {
//       return <div className="success-message">{message}</div>;
//     };
  
//     const handleChange = (e) => {
//       setFormData({ ...formData, [e.target.name]: e.target.value });
      
//     };
//     const validatePasswords = () => {
//       if (formData.password !== formData.confirm_password) {
//         setError('Passwords do not match');
//         return false;
//       } else {
//         setError(null);
//         return true;
//       }
//     };
//     const handleSubmit = async (e) => {
//       e.preventDefault();
//       try {
//         if (validatePasswords()) {
//           console.log("Submitting data:", formData);
//           const response = await axios.post(
//             "http://127.0.0.1:8000/api/register/",
//             formData
//             );
//             console.log(response.data);
//             setSuccessMessage("Form submitted successfully!");
//             setError(null);
//         } else {
//           setError(true);
//           console.log("error");
//         }
//       } catch (error) {
//         console.error(error);
//         setError("An error occurred while submitting the form.");
//         setSuccessMessage(null);
//       }
//     };
  
//   return (
//     <div>
//         <p>joinform</p>
//         <section className="text-center ">
//           <h1>Join Us</h1>
//           <p>We Stand For Unrivaled Excellence</p>
//         </section>
//         <section>
//           <form onSubmit={handleSubmit}>
//             <Form.Label>
//               Email
//               <Form.Control
//                 type="email"
//                 name="email"
//                 placeholder="Email"
//                 onChange={handleChange}
//                 required
//               />
//             </Form.Label>
//             <Form.Label>
//               First Name
//               <Form.Control
//                 type="text"
//                 name="first_name"
//                 placeholder="First Name"
//                 onChange={handleChange}
//                 required
//               />
//             </Form.Label>
//             <Form.Label>
//               Last Name
//               <Form.Control
//                 type="text"
//                 name="last_name"
//                 placeholder="Last Name"
//                 onChange={handleChange}
//                 required
//               />
//             </Form.Label>
//             <Form.Label>
//               Phone Number
//               <Form.Control
//                 type="text"
//                 name="phone_number"
//                 placeholder="+2348000000000"
//                 onChange={handleChange}
//                 required
//               />
//             </Form.Label>
//             <Form.Label>
//               Password
//               <Form.Control
//                 type="password"
//                 name="password"
//                 placeholder="Password"
//                 onChange={handleChange}
//                 required
//               />
//             </Form.Label>
//             <Form.Label>
//               Confirm Password
//               <Form.Control
//                 type="password"
//                 name="confirm_password"
//                 placeholder="Confirm Password"
//                 onChange={handleChange}
//                 required
//                 />
//             </Form.Label>
//                 {error && <p>{error}</p>}
//             <Form.Label>
//               Bio
//               <textarea
//                 name="bio"
//                 placeholder="Tell us a bit about Yourself"
//                 onChange={handleChange}
//                 required
//               ></textarea>
//             </Form.Label>
//             <Form.Label>
//               State of Origin
//               <Form.Select name="state" onChange={handleChange} required>
//                 <option value="">Select a state</option>
//                 <option value="AB" key="AB">
//                   Abia
//                 </option>
//                 <option value="AD" key="AD">
//                   Adamawa
//                 </option>
//                 <option value="AK" key="AK">
//                   Akwa Ibom
//                 </option>
//                 <option value="AN" key="AN">
//                   Anambra
//                 </option>
//                 <option value="BA" key="BA">
//                   Bauchi
//                 </option>
//                 <option value="BY" key="BY">
//                   Bayelsa
//                 </option>
//                 <option value="BE" key="BE">
//                   Benue
//                 </option>
//                 <option value="BO" key="BO">
//                   Borno
//                 </option>
//                 <option value="CR" key="CR">
//                   Cross River
//                 </option>
//                 <option value="DE" key="DE">
//                   Delta
//                 </option>
//                 <option value="EB" key="EB">
//                   Ebonyi
//                 </option>
//                 <option value="ED" key="ED">
//                   Edo
//                 </option>
//                 <option value="EK" key="EK">
//                   Ekiti
//                 </option>
//                 <option value="EN" key="EN">
//                   Enugu
//                 </option>
//                 <option value="FC" key="FC">
//                   Federal Capital Territory
//                 </option>
//                 <option value="GO" key="GO">
//                   Gombe
//                 </option>
//                 <option value="IM" key="IM">
//                   Imo
//                 </option>
//                 <option value="JI" key="JI">
//                   Jigawa
//                 </option>
//                 <option value="KD" key="KD">
//                   Kaduna
//                 </option>
//                 <option value="KN" key="KN">
//                   Kano
//                 </option>
//                 <option value="KT" key="KT">
//                   Katsina
//                 </option>
//                 <option value="KE" key="KE">
//                   Kebbi
//                 </option>
//                 <option value="KO" key="KO">
//                   Kogi
//                 </option>
//                 <option value="KW" key="KW">
//                   Kwara
//                 </option>
//                 <option value="LA" key="LA">
//                   Lagos
//                 </option>
//                 <option value="NA" key="NA">
//                   Nasarawa
//                 </option>
//                 <option value="NI" key="NI">
//                   Niger
//                 </option>
//                 <option value="OG" key="OG">
//                   Ogun
//                 </option>
//                 <option value="ON" key="ON">
//                   Ondo
//                 </option>
//                 <option value="OS" key="OS">
//                   Osun
//                 </option>
//                 <option value="OY" key="OY">
//                   Oyo
//                 </option>
//                 <option value="PL" key="PL">
//                   Plateau
//                 </option>
//                 <option value="RI" key="RI">
//                   Rivers
//                 </option>
//                 <option value="SO" key="SO">
//                   Sokoto
//                 </option>
//                 <option value="TA" key="TA">
//                   Taraba
//                 </option>
//                 <option value="YO" key="YO">
//                   Yobe
//                 </option>
//                 <option value="ZA" key="ZA">
//                   Zamfara
//                 </option>
//               </Form.Select>
//             </Form.Label>
//             <Form.Label>
//               Address
//               <Form.Control
//                 type="text"
//                 name="address"
//                 placeholder="Address"
//                 onChange={handleChange}
//                 required
//               />
//             </Form.Label>
//             <Form.Label>
//               Occupation
//               <Form.Control
//                 type="text"
//                 name="occupation"
//                 placeholder="Occupation"
//                 onChange={handleChange}
//                 required
//               />
//             </Form.Label>
//             <Form.Label>
//               Linkedin
//               <Form.Control
//                 type="url"
//                 name="linkedin"
//                 placeholder="https://linkedin.com/in/username"
//                 onChange={handleChange}
//                 required
//               />
//             </Form.Label>
//             <Form.Label>
//               Twitter
//               <Form.Control
//                 type="url"
//                 name="twitter"
//                 placeholder="https://twitter.com/username"
//                 onChange={handleChange}
//                 required
//               />
//             </Form.Label>
//             <Form.Label>
//               Website/Portfolio
//               <Form.Control
//                 type="url"
//                 name="website"
//                 placeholder="https://sitename.com"
//                 onChange={handleChange}
//                 required
//               />
//             </Form.Label>
//             <Form.Label>
//               Why Do You Want to Join Us
//               <textarea
//                 name="why_join"
//                 placeholder="Why Do You Want to Join Us"
//                 onChange={handleChange}
//                 required
//               ></textarea>
//             </Form.Label>
//             <Form.Label>
//               How did you hear about TechHive?
//               <Form.Select name="referral_source" onChange={handleChange} required>
//                 <option value="">Select an option</option>
//                 <option value="Linkedin" key="Linkedin">
//                   Linkedin
//                 </option>
//                 <option value="Facebook" key="Facebook">
//                   Facebook
//                 </option>
//                 <option value="Google" key="Google">
//                   Google
//                 </option>
//                 <option value="From a Friend" key="From a Friend">
//                   From a Friend
//                 </option>
//                 <option value="Online Blogs" key="Online Blogs">
//                   Online Blogs
//                 </option>
//                 <option value="Other" key="Other">
//                   Other
//                 </option>
//               </Form.Select>
//             </Form.Label>
//             <button type="submit">Submit</button>
//           </form>
//         </section>
//         {error && <Error message={error} />}
//         {successMessage && <Success message={successMessage} />}
//     </div>
//   )
// }

// export default JoinForm