import React, {  useContext } from "react";
import NavbarSection from "./NavbarSection";
import FooterSection from "./FoooterSection";
import ThemeContext from './Themes/ThemeContext';
import './StylingArena/Contact.css'

function Contact() {
    const { darkMode, toggleDarkMode } = useContext(ThemeContext);
  return (
    <div className={`  ${darkMode ? 'dark-mode' : ''}`}>
        <NavbarSection onToggle={toggleDarkMode}/>
        <section className="contactsection">

       <h1 className="contacthead">Contact Us</h1>
       <p className="contacttext">
We'd love to hear from you! If you have any questions, comments, or concerns, please don't hesitate to get in touch with us.
       </p>
       <p className="contacttext">You can reach us by email at <span className="contactemail">info@techhive.ng</span> . We'll do our best to respond to your message as quickly as possible.</p>
       <p className="contacttext">Thank you for your interest in Techhive!</p>
        </section>
        <FooterSection darkMode={darkMode}/>
        </div>
  )
}

export default Contact