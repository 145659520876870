import React, { useContext } from "react";
import { Link} from "react-router-dom";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import ThemeContext from "./Themes/ThemeContext";
import './StylingArena/NavbarSection.css'

function NavbarSection() {
  const { darkMode, toggleDarkMode } = useContext(ThemeContext);
  // const navigate = useNavigate();
  // const token = localStorage.getItem('token');

  // const handleLogout = () => {
  //   // Remove the token from localStorage
  //   localStorage.removeItem('token');
  //   // Redirect to the login page
  //   navigate('/login');
  // };
  return (
    <div
    className={`navtotal  py-3 ${darkMode ? 'dark-mode' : ''}`}>
      <div className="text-center py-2">
      <Link to={"/"} >
        <img src="/images/techhivelogo.png" alt="Techhive" className="image-fluid techhivelogo" />
              </Link>
      </div>

    <Navbar
    collapseOnSelect
    expand="lg"
    bg="warning"
    variant="dark"
    sticky ="top"
     >

     
        <Container>
          <Navbar.Brand href="#">
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mx-auto">
              <Link to={"/"} className="btn btn-dark my-4 py-3 ml-2 m-2">
                <i class="bi bi-house"></i> Home
              </Link>
              <Link to={"/join-us"} className="btn btn-dark my-4 py-3 ml-2 m-2">
                <i class="bi bi-globe-europe-africa"></i> Join Us
              </Link>
              <Link to={"/careers"} className="btn btn-dark my-4 py-3 ml-2 m-2">
                <i class="bi bi-briefcase"></i> Jobs/Careers
              </Link>
              <Link
                to={"/hackathons"}
                className="btn btn-dark my-4 py-3 ml-2 m-2"
              >
                <i class="bi bi-file-code-fill"></i> Hackathons/Scholarships
              </Link>
              {/* <Link
                to={"/our-elite"}
                className="btn btn-dark my-4 py-3 ml-2 m-2"
              >
                <i class="bi bi-code-slash"></i> Our Elite
              </Link> */}
              <Link to={"/events"} className="btn btn-dark my-4 py-3 ml-2 m-2">
                <i class="bi bi-calendar-event"></i> Events
              </Link>
              <Link
                to={"/our-founders"}
                className="btn btn-dark my-4 py-3 ml-2 m-2"
              >
                <i class="bi bi-person-lines-fill"></i> Founders
              </Link>
              <NavDropdown
                className="btn btn-dark my-4 ml-2 m-2 navlink"
                title="More"
                id="collasible-nav-dropdown"
              >
                <NavDropdown.Item   className="navlinksec ">
                {/* { token ? (
                  <Link onClick={handleLogout} className="navlinksec "><i class="bi bi-box-arrow-in-left"></i> Logout</Link>
                  ) : (
                    <Link to= {'/login'} className="navlinksec "><i class="bi bi-box-arrow-in-right"></i> Login</Link>
      )} */}
                </NavDropdown.Item>
                <NavDropdown.Item className="navlinksec ">
                  <Link to= {'/blog'} className="navlinksec "><i class="bi bi-newspaper"></i> Visit Blog</Link>
                </NavDropdown.Item>
                <NavDropdown.Item  >
                  <Link to={'/sponsors'}className="navlinksec "><i class="bi bi-cash-coin"></i> Sponsors</Link>
                
                </NavDropdown.Item>
                <NavDropdown.Item  >
                  <Link to={'/our-elite'}className="navlinksec "><i class="bi bi-code-slash"></i> Top Performers</Link>
                
                </NavDropdown.Item>
                <NavDropdown.Item  >
                  <Link to={'/our-founders'}className="navlinksec "><i class="bi bi-people-fill"></i>Our Founders</Link>
                
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  target="_blank"
                  href="https://www.youtube.com/@techhiveng"
                  className="navlinksec "
                >
                  <i class="bi bi-youtube"></i> Youtube Channel
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
            {/* <Nav>
            <Nav.Link href="#deets">More deets</Nav.Link>
            <Nav.Link eventKey={2} href="#memes">
            Dank memes
            </Nav.Link>
          </Nav> */}
          

            <h5 className="mx-auto darkswitch text-center p-2" onClick={toggleDarkMode}>
              {darkMode ? (
                <i className="bi bi-moon-fill text-black"> <br />dark</i>
              ) : (
                <i className="bi bi-sun-fill text-success"><br />light</i>
              )}
            </h5>
       
          </Navbar.Collapse>
        </Container>
    
    </Navbar>
                </div>
  );
}

export default NavbarSection;
