import React, { useContext, useState, useEffect } from "react";
import { Col, Card, Button, Modal, Badge, Table } from "react-bootstrap";
import NavbarSection from "./NavbarSection";
import FooterSection from "./FoooterSection";
import ThemeContext from './Themes/ThemeContext';
import CourseCard from "./CourseCard";
import './StylingArena/Classes.css'


const API_TOKEN = process.env.REACT_APP_API_TOKEN;
function Classes() {
    const { darkMode, toggleDarkMode } = useContext(ThemeContext);
    const [courses, setCourses] = useState([]);
    const [showModal, setShowModal] = useState(false);
    // const location = useLocation();
    const [selectedCourse, setSelectedCourse] = useState(null);
    useEffect(() => {
      async function fetchCourses() {
        try {
          const response = await fetch(
            "https://backend.techhive.ng/api/courses",
            {
              headers: {
                Authorization: `Token ${API_TOKEN}`,
                "Content-Type": "application/json",
              },
            }
          );
          const data = await response.json();
          setCourses(data.results);
          console.log(data);
        } catch (error) {
          console.error("Error fetching courses:", error);
        }
      }
      fetchCourses();
    }, []);
    function handleModal(course = null) {
      setSelectedCourse(course);
      setShowModal(!showModal);
    }
    function formatText(text) {
      return text.split("\n").map((item, key) => {
        return (
          <span key={key}>
            {item}
            <br />
          </span>
        );
      });
    }
    // useEffect(() => {
    //   setSidebarOpen(false);
    // }, [location]);
  return (
    <div className={`  ${darkMode ? 'dark-mode' : ''}`}>
        <NavbarSection onToggle={toggleDarkMode}/>
        <div>
          <h3 className="text-center classesheadjoin">Join Our Classes</h3>
          <section className="classessection">

          <h5 className="classestalk">Are you tired of watching videos and reading e-books online?  TechHive has a different and unique approach to learning.</h5>
         <h4 className="classestalk"> Empower Your Future with Knowledge:</h4>
          <p > "At TechHive, we believe in the transformative power of learning. Our unique online courses are designed to arm you with the skills you need to succeed in today's digital world. Say goodbye to endless videos and eBooks. Say hello to interactive, engaging, and comprehensive learning."</p>
         <h4 className="classestalk"> Personalized Learning Experience:</h4>
          <p > "We understand that everyone learns differently. That's why our courses are designed to fit your unique learning style. From interactive video lessons to hands-on projects, we provide a variety of learning formats that keep you engaged and motivated."</p>
         <h4 className=" classestalk"> Expert Tutors at Your Fingertips:</h4>
          <p > "Our team of expert tutors is the backbone of TechHive. They're industry professionals who are passionate about sharing their knowledge and experience. They're here to guide you every step of the way, providing personalized attention and support."</p>
         <h4 className="classestalk"> Join a Thriving Community:</h4>
          <p >"When you sign up for a TechHive course, you're not just a student - you're a part of our community. Network with fellow learners, share ideas, collaborate on projects, and make lasting connections."</p>
         <h4 className="classestalk"> Learn At Your Pace, On Your Schedule:</h4>
          <p > "We know you're busy. That's why TechHive's online classes are flexible and self-paced. You can learn whenever you want, wherever you want - all you need is an internet connection."</p>
         <h4 className="classestalk"> Premium Learning, Affordable Pricing:</h4>
          <p > "We believe quality education should be accessible to everyone. Whether you choose our free or premium classes, you'll get the same high-quality instruction and learning resources. Plus, our premium classes offer extra perks like one-on-one time with tutors and advanced course materials."</p>
          </section>
          <section className="classessection">
            <h3 className="text-center classestalk py-3">Dive Into a World of Learning with TechHive</h3>
            <p>
            Whether you're looking to dip your toes into the exciting world of web development, explore the transformative potential of blockchain technology, get creative with digital marketing, or venture into the ever-expanding landscape of application development, we've got a course just for you.
            </p>
            <ul>
              <h5 className="classestalk">We proudly offer courses across various fields including:</h5>
              <li>Web Development</li>
              <li>Programming</li>
              <li>Application Development</li>
              <li>Blockchain & Cryptocurrency</li>
              <li>Forex Trading</li>
              <li>Blogging</li>
              <li>Digital Marketing</li>
              <li>Article Writing & Content Creation</li>
            </ul>
          </section>
        </div>
        {/* <section className="my-2 py-2 "> */}
  {/* <h3 className="p-5 classeshead">Web Development</h3> */}
  <div className="coursecards">

  {courses.map((course, index) => (
    <CourseCard key={index} course={course} handleModal={handleModal} />
    ))}
    </div>
{/* </section> */}

        {/* <section className="my-3 py-3">
            <h3 className="p-5 classeshead">Application Development</h3>
            <p className="text-center">Coming Soon...</p>
        </section>
        <section className="my-3 py-3">
            <h3 className="p-5 classeshead">Blockchain & Cryptocurrency</h3>
            <p className="text-center">Coming Soon...</p>
        </section>
        <section className="my-3 py-3">
            <h3 className="p-5 classeshead">Forex Trading</h3>
            <p className="text-center">Coming Soon...</p>
        </section>
        <section className="my-3 py-3">
            <h3 className="p-5 classeshead">Blogging</h3>
            <p className="text-center">Coming Soon...</p>
        </section>
        <section className="my-3 py-3">
            <h3 className="p-5 classeshead">Digital Marketing</h3>
            <p className="text-center">Coming Soon...</p>
        </section>
        <section className="my-3 py-3">
            <h3 className="p-5 classeshead">Writing</h3>
            <p className="text-center">Coming Soon...</p>
        </section> */}
        <section className="m-2 p-3">
        <p className="text-center classesheadjoin">Free vs Premium</p>
      <Table className={`  ${
              darkMode ? "dark-table-header" : ""
            }`}>
        <thead className="table-dark">
          <tr>
            <th scope="col"></th>
            <th scope="col">Free Classes</th>
            <th scope="col">Premium Classes</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">Content</th>
            <td>Basic Topics</td>
            <td>Advanced, Specialized Topics & Real-life Projects</td>
          </tr>
          <tr>
            <th scope="row">Instructors</th>
            <td>Less Experienced Instructors</td>
            <td>Industry Professionals</td>
          </tr>
          <tr>
            <th scope="row">Class Size</th>
            <td>Larger</td>
            <td>Smaller, More Individual Attention</td>
          </tr>
          <tr>
            <th scope="row">Access to Instructor</th>
            <td>Limited</td>
            <td>Direct Access for Q&A</td>
          </tr>
          <tr>
            <th scope="row">Learning Materials</th>
            <td>Basic Materials</td>
            <td>Additional Learning Materials</td>
          </tr>
          <tr>
            <th scope="row">Certificates</th>
            <td>No</td>
            <td>Coming Soon...</td>
          </tr>
          <tr>
            <th scope="row">Support</th>
            <td>Basic Support</td>
            <td>Extended Support</td>
          </tr>
          <tr>
            <th scope="row">Community</th>
            <td>No</td>
            <td>Access to Community</td>
          </tr>
          <tr>
            <th scope="row">Access to Class Recordings</th>
            <td>Temporary Access</td>
            <td>Lifetime Access</td>
          </tr>
        </tbody>
      </Table>
    </section>
    <Modal show={showModal} onHide={() => handleModal()}>
          <Modal.Header className="bg-warning" closeButton>
            <Modal.Title>
              <strong>{selectedCourse && selectedCourse.title}</strong>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            className={` modal-body-custom ${darkMode ? "dark-mode" : ""}`}
          >
            <p>
              <strong>Capacity:</strong>{" "}
              {selectedCourse && selectedCourse.max_capacity}
            </p>
            <p>
              <strong>Slots Left:</strong>{" "}
              {selectedCourse && selectedCourse.slots_left}
            </p>
            <p>
              <strong>Description:</strong>{" "}
              {selectedCourse && formatText(selectedCourse.description)}
            </p>
            <br />
            <p>
              <strong>Amount:</strong> ₦
              {selectedCourse && selectedCourse.amount}
            </p>
            <br />

            {selectedCourse && selectedCourse.instructor && (
              <div>
                <strong>Instructor(s):</strong>
                {selectedCourse.instructor.map((instructord, index) => (
                  <div key={index}>
                    <p>Name- {instructord.name}</p>
                    <p>About- {instructord.bio}</p>
                    <p>Contact- {instructord.email}</p>
                    {/* Add other organizer properties if needed */}
                  </div>
                ))}
              </div>
            )}
            {selectedCourse && selectedCourse.sponsors && (
              <div>
                <strong>Sponsors:</strong>
                {selectedCourse.sponsors.map((sponsor, index) => (
                  <div key={index}>
                    <p>{sponsor.name}</p>
                    <img src={sponsor.image} alt="" className="img-fluid" />
                    <p>{sponsor.description}</p>
                    {/* Add other organizer properties if needed */}
                  </div>
                ))}
              </div>
            )}
            {/* Add more data from the API response here */}
          </Modal.Body>
          <Modal.Footer className="bg-warning">
            <Button variant="secondary" onClick={() => handleModal()}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <FooterSection darkMode={darkMode}/>
        </div>
  )
}

export default Classes