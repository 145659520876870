import React, { useState, useContext, useEffect } from 'react';
import { Table, Button, Modal } from 'react-bootstrap'; // add Modal from react-bootstrap
import { useLocation } from 'react-router-dom';
import ThemeContext from './Themes/ThemeContext';
import Sidebar from './Sidebar'
import './StylingArena/Jobs.css'

function formatDate(dateString) {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "short",
  };
  const date = new Date(dateString);
  return date.toLocaleString("en-US", options);
}
const API_TOKEN = process.env.REACT_APP_API_TOKEN;
function Jobs() {
  const [jobs, setJobs] = useState([]);
  const [selectedProJob, setSelectedProJob] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { darkMode, toggleDarkMode } = useContext(ThemeContext);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const location = useLocation();

  useEffect(() => {
    async function fetchJobs() {
      try {
        const response = await fetch("https://backend.techhive.ng/api/projobs", {
          headers: {
            Authorization: `Token ${API_TOKEN}`,
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        setJobs(data.results);

      } catch (error) {
        console.error("Error fetching jobs:", error);
      }
    }
    fetchJobs();
  }, []);
  const handleShowModal = (job) => {
    setSelectedProJob(job);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    setSidebarOpen(false);
  }, [location]);
  function formatText(text) {
    return text.split('\n').map((item, key) => {
      return <span key={key}>{item}<br/></span>
    })
  }
  return (
    <div className={`  ${darkMode ? 'dark-mode' : ''}`}  style={{ display: 'flex',}}>
    <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} onToggle={toggleDarkMode}/>
<div className='jobdivright'>
<nav>
<div className="bg-warning p-1 sticky-top "  style={{ display: 'flex',  justifyContent: 'space-between'}}>      <h2 onClick={() => setSidebarOpen(!sidebarOpen)}>
{sidebarOpen ? <i className="bi bi-list p-2"></i> : <i className="bi bi-list p-2"></i>}
</h2>
<div className="my-1">
      {/* <button className="btn btn-warning" onClick={handleLogoutx}>
      <FaArrowLeft/> Logout
    </button> */}
      <button className="btn " onClick={toggleDarkMode}>
        {darkMode ? (
          <h4>
            <i className="bi bi-sun-fill text-success"></i>
          </h4>
        ) : (
          <h4>
            <i className="bi bi-moon-fill text-black"></i>
          </h4>
        )}
      </button>
    </div>
</div>
</nav>
<div className='jobcontainerright text-center'>
<h3 className='jobheader my-3 '>Looking To Get Hired?</h3>
<h4 className='p-2'>Let's do the job hunting for you <br /> Apply for exclusive remote and on-site tech jobs in Nigeria</h4>

 <section>
 {jobs.length === 0 ? (
        <p className="text-center">
          Sorry, there are no open roles at the moment. Please check again
          later.
        </p>
      ) : (
        <div className=" m-3 p-3">
<Table
            className={`text-center  ${
              darkMode ? "dark-table-header" : ""
            }`}
            striped
            bordered
            hover
            responsive
          >
            <thead>
              <tr>
                <th>Role</th>
                <th>Company</th>
                <th>Location</th>
                <th>Job Type</th>
                <th>Action/Details</th>
              </tr>
            </thead>
            <tbody>
              {jobs.map((projob) => (
                <tr
                  key={projob.id}
                  className={`${darkMode ? "dark-table-row" : ""}`}
                >
                  <td>{projob.title}</td>
                  <td>{projob.company}</td>
                  <td>{projob.location}</td>
                  <td>{projob.job_type}</td>
                  <td>
                  <Button
                                 variant="warning"
                                 onClick={() => handleShowModal(projob)}
                               >
                                 More Details
                               </Button>

                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {selectedProJob && (
            <Modal show={showModal} onHide={handleCloseModal}>
              <Modal.Header className="bg-warning" closeButton>
                <Modal.Title>{selectedProJob.title}</Modal.Title>
              </Modal.Header>
              <Modal.Body className={` modal-body-custom ${darkMode ? 'dark-mode' : ''}`}>
                <h5>Company</h5>
                <p>{selectedProJob.company}</p>
                <h5>Location</h5>
                <p>
                  <i class="bi bi-geo-alt"></i>
                  {selectedProJob.location}
                </p>
                <h5>Job Type</h5>
                <p>{selectedProJob.job_type}</p>
                <h5>Description</h5>
                <p>{formatText(selectedProJob.description)}}</p>
                <h5>Salary Range</h5>
                <p>{selectedProJob.salary}</p>
                <h5>Date Posted</h5>
                <p>{formatDate(selectedProJob.date_posted)}</p>
                <h5>How to Apply</h5>
                <p>{formatText(selectedProJob.how_to_apply)}}</p>
              </Modal.Body>
              <Modal.Footer className="bg-warning">
                <Button variant="secondary" onClick={handleCloseModal}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </div>
      )}
 </section>
</div>
</div>

</div>
  )
}

export default Jobs