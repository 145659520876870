import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import HomePage from './components/HomePage';
import Careers from './components/Careers';
import Hackathons from './components/Hackathons';
import JoinUs from './components/JoinUs';
import OurElite from './components/OurElite';
import Events from './components/Events';
import Sponsors from './components/Sponsors';
import ArticleHive from './components/ArticleHive';
import BlogList from './components/BlogList';
import Classes from './components/Classes';
import ErrorPage from './components/ErrorPage';
import PrivacyPolicy from './components/PrivacyPolicy';
import Contact from './components/Contact';
import About from './components/About';
import Jobs from './components/Jobs';
import Profile from './components/Profile';
import Report from './components/Report';
import TermsOfService from './components/TermsOfService';
import Login from './components/Login';
import Courses from './components/Courses';
import ConfirmEmail from './components/ConfirmEmail';
import Community from './components/Community';
import PasswordReset from './components/PasswordReset';
import OurFounders from './components/OurFounders';
import ScrollToTopButton from "./components/ScrollTotopButton";
import ScrollToTop from './components/ScrollToTop';
import ThemeContext from './components/Themes/ThemeContext';

function App() {
  const [darkMode, setDarkMode] = useState(() => {
    const savedDarkMode = localStorage.getItem('darkMode');
    return savedDarkMode !== null ? JSON.parse(savedDarkMode) : true;
  });

  const toggleDarkMode = () => setDarkMode(!darkMode);
  // const buttonText = darkMode ? 'Light Mode' : 'Dark Mode';
  
  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
  }, [darkMode]);
  return (
    <ThemeContext.Provider value={{ darkMode, toggleDarkMode}}>
    <Router>
    <ScrollToTop />
    <ScrollToTopButton />
      <Routes>
        
        <Route path="/" element={<HomePage />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/hackathons" element={<Hackathons />} />
        <Route path="/join-us" element={<JoinUs/>}/>
        <Route path="/our-elite" element={<OurElite/>}/>
        <Route path="/our-founders" element={<OurFounders/>}/>
        <Route path="/events" element={<Events/>}/>
        <Route path="/courses" element={<Courses/>}/>
        <Route path="/sponsors" element={<Sponsors/>}/>
        <Route path="/community" element={<Community/>}/>
        <Route path="/classes" element={<Classes/>} />
        <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/jobs" element={<Jobs/>} />
        <Route path="/profile" element={<Profile/>} />
        <Route path="/report" element={<Report/>} />
        <Route path="/login" element={<Login/>} />
        <Route path="/terms-of-service" element={<TermsOfService/>} />
        <Route path="/blog" element={<BlogList/>} />
        <Route path="/blog/:slug" element={<ArticleHive/>} />
        <Route path="/reset-password/:token" element={<PasswordReset />} />
        <Route path="/confirm-email/:token" element={<ConfirmEmail />} />
  
        {/* Other routes */}
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Router>
  </ThemeContext.Provider>
  );
}

export default App;
