import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { Form, Button } from 'react-bootstrap'; // add Modal from react-bootstrap
import { useLocation } from 'react-router-dom';
import ThemeContext from './Themes/ThemeContext';
import Sidebar from './Sidebar'
import './StylingArena/Profile.css'


// const API_TOKEN = process.env.REACT_APP_API_TOKEN;
function Profile() {
  const { darkMode, toggleDarkMode } = useContext(ThemeContext);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const location = useLocation();
  const userToken = localStorage.getItem('token');
  const [userData, setUserData] = useState({}); // State to store user details
  const [formData, setFormData] = useState({
    // Initialize state with all the fields from your model
    email: '',
    bio: '',
    phone_number: '',
    state: '',
    address: '',
    occupation: '',
    linkedin: '',
    twitter: '',
    website: '',
    why_join: '',
    referral_source: '',
});

const handleChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
}

const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        const response = await axios.put(
            'https://backend.techhive.ng/api/profile/', 
            formData,
            {
                headers: {
                    'Authorization': `Token ${userToken}`,
                    'Content-Type': 'application/json',
                },
            }
        );
        
        console.log(response.data);
        setUserData(response.data);
    } catch(error) {
        console.error('Error:', error);
    }
}

useEffect(() => {
    // Load the user's current details when the component mounts
    const loadUserDetails = async () => {
        try {
            const response = await axios.get(
                'https://backend.techhive.ng/api/profile/', 
                {
                    headers: {
                        'Authorization': `Token ${userToken}`,
                    },
                }
            );
            
            setFormData(response.data); // Set the form data
            setUserData(response.data); // Set the user data
        } catch(error) {
            console.error('Error:', error);
        }
    }

    loadUserDetails();
}, [userToken]);

  useEffect(() => {
    setSidebarOpen(false);
  }, [location]);
  return (
    <div className={`  ${darkMode ? 'dark-mode' : ''}`}  style={{ display: 'flex',}}>
    <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} onToggle={toggleDarkMode}/>
<div className='profiledivright'>
<nav>
<div className="bg-warning p-1 sticky-top "  style={{ display: 'flex',  justifyContent: 'space-between'}}>      <h2 onClick={() => setSidebarOpen(!sidebarOpen)}>
{sidebarOpen ? <i className="bi bi-list p-2"></i> : <i className="bi bi-list p-2"></i>}
</h2>
<div className="my-1">
      {/* <button className="btn btn-warning" onClick={handleLogoutx}>
      <FaArrowLeft/> Logout
    </button> */}
      <button className="btn " onClick={toggleDarkMode}>
        {darkMode ? (
          <h4>
            <i className="bi bi-sun-fill text-success"></i>
          </h4>
        ) : (
          <h4>
            <i className="bi bi-moon-fill text-black"></i>
          </h4>
        )}
      </button>
    </div>
</div>
</nav>
<div className='profilecontainerright text-center'>
<h3 className='profileheader my-3 '>My Profile</h3>
{/* <h4 className='p-2'>Let's do the job hunting for you <br /> Apply for remote and on-site tech jobs in Nigeria</h4> */}
<div>

            {/* Display user's details */}
            <div>
                <p>First Name: {userData.first_name}</p>
                <p>First Name: {userData.name}</p>
                <p>Bio: {userData.bio}</p>
                {/* Add more fields here... */}
            </div>
            <h3 className='bg-danger my-3 '>This Page Is Still Under Construction....Anticipate😉</h3>
            <h3>Edit Profile</h3>
            <Form onSubmit={handleSubmit} className='m-5'>
                {/* You'll need a form field for each field in your model */}
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" name="email" onChange={handleChange} value={formData.email} />

                <Form.Label>Bio</Form.Label>
                <Form.Control type="text" name="bio" onChange={handleChange} value={formData.bio} />

                {/* Add more form fields here... */}

                <Button variant='warning' type="submit" value="Submit">
                    Update Profile
                </Button>
            </Form>
        </div>
 <div>
  <p>
    Want to build a remarkable cv/resume? <br /> Let the experts handle it, for as little as 2000naira only!!. click here to begin📁
  </p>
 </div>
</div>
</div>

</div>
  )
}

export default Profile