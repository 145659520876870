import React, {  useContext } from "react";
import NavbarSection from "./NavbarSection";
import FooterSection from "./FoooterSection";
import ThemeContext from './Themes/ThemeContext';
import './StylingArena/PrivacyPolicy.css';

function PrivacyPolicy() {
    const { darkMode, toggleDarkMode } = useContext(ThemeContext);
  return (
    <div className={`  ${darkMode ? 'dark-mode' : ''}`}>
        <NavbarSection onToggle={toggleDarkMode}/>
       <section className="privacysection">
        <h1 className="text-center">Privacy Policy</h1>
        <p>
        This Privacy Policy governs the manner in which TechHive collects, uses, maintains, and discloses information collected from users (each, a "User") of the TechHive website (https://techhive.ng) ("Site"). This privacy policy applies to the Site and all products and services offered by TechHive.
        </p>
        <h3>Personal Identification Information</h3>
        <p>
        We may collect personal identification information from Users in a variety of ways, including but not limited to when Users visit our site, register on the site, fill out a form, subscribe to the newsletter, and in connection with other activities, services, features or resources we make available on our Site. Users may be asked for, as appropriate, name, email address, phone number, and other relevant information. Users may, however, visit our Site anonymously. We will collect personal identification information from Users only if they voluntarily submit such information to us. Users can always refuse to supply personally identification information, except that it may prevent them from engaging in certain Site-related activities.
        </p>
        <h3>
        Non-personal Identification Information
        </h3>
        <p>
        We may collect non-personal identification information about Users whenever they interact with our Site. Non-personal identification information may include the browser name, the type of computer, and technical information about Users' means of connection to our Site, such as the operating system and the Internet service providers utilized and other similar information.
        </p>
        <h3>
        Web Browser Cookies
        </h3>
        <p>
        Our Site may use "cookies" to enhance the User experience. Users' web browsers place cookies on their hard drive for record-keeping purposes and sometimes to track information about them. Users may choose to set their web browser to refuse cookies or to alert them when cookies are being sent. If they do so, note that some parts of the Site may not function properly.
        </p>
        <h3>
        How We Use Collected Information
        </h3>
        <p>TechHive may collect and use Users' personal information for the following purposes:</p>
        <ul>
            <li>To improve customer service: Information provided by Users helps us respond to their customer service requests and support needs more efficiently.</li>
            <li>To personalize user experience: We may use information in the aggregate to understand how our Users as a group use the services and resources provided on our Site.</li>
            <li>To send periodic emails: We may use the email address to respond to inquiries, questions, and/or other requests from Users. If a User decides to opt-in to our mailing list, they will receive emails that may include news, updates, related product or service information, etc.</li>
        </ul>
        <h3>
        How We Protect Your Information
        </h3>
        <p>
        We adopt appropriate data collection, storage, and processing practices and security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information, username, password, transaction information, and data stored on our Site.
        </p>
        <h3>Sharing Your Personal Information</h3>
        <p>
        We do not sell, trade, or rent Users' personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates, and advertisers for the purposes outlined above.
        </p>
        <h3>Third-Party Websites</h3>
        <p>
        Users may find advertising or other content on our Site that links to the sites and services of our partners, suppliers, advertisers, sponsors, licensors, and other third parties. We do not control the content or links that appear on these sites and are not responsible for the practices employed by websites linked to or from our Site. These sites and services may have their own privacy policies and customer service policies. Browsing and interaction on any other website, including websites that have a link to our Site, is subject to that website's own terms and policies.
        </p>
        <h3>
        Changes to This Privacy Policy
        </h3>
        <p>TechHive has the discretion to update this privacy policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage Users to frequently check this page for any changes to stay informed about how we may use and protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.</p>
        <h3>Contacting Us</h3>
        <p>If you have any questions about this Privacy Policy, the practices of this site, or your dealings with this site, please contact us at:</p>
        <p><b>TechHive</b>
            <p>Email: info@techhive.ng</p>
        </p>
        <p><b>This document was last updated on May 10, 2023.</b></p>
       </section>
        <FooterSection darkMode={darkMode}/>
        </div>
  )
}

export default PrivacyPolicy