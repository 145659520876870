import React from "react";
import {Card, Badge } from "react-bootstrap";
import './StylingArena/CourseCard.css'

const sectionColors = {
    'Web Development': 'primary',
    'Programming': 'primary',
    'Application Development': 'secondary',
    'Blockchain & Cryptocurrency': 'success',
    'Forex Trading': 'danger',
    'Blogging': 'warning',
    'Digital Marketing': 'info',
    'Writing': 'dark',
    // add more sections as needed
  };

function CourseCard({ course, handleModal }) {
    const sectionColor = sectionColors[course.section];
  return (
    <div className=" my-3 py-3 mx-3">

    <Card className="card-custom" style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title className='text-capitalize'>{course.title}</Card.Title>
      <Card.Img variant="top" src={course.image} height={200} width={250}/>
        <Badge className={`mb-2 bg-${sectionColor}`}>{course.section}</Badge> <br />
        {course.type === 'premium' && <Badge className="bg-warning">Premium</Badge>}
        {course.type === 'free' && <Badge className="bg-success">Free</Badge>}
        <Card.Text>
        {course.description.split('\n')[0]}
        {/* displays first paragraph or line */}
        </Card.Text>
        <ul>
        {course.topics.split(',').map((topic, index) => (
              <li key={index}>{topic.trim()}</li>
            ))}
        </ul>
        <Card.Link href="https://wa.me/2349050163066?text=I'm%20interested%20in%20registering%20for%20a%20class" className="btn btn-warning">Register</Card.Link>
        <Card.Link
                      className="btn btn-warning"
                      onClick={() => handleModal(course)}
                    >
                      More Details
                    </Card.Link>
      </Card.Body>
    </Card>
              </div>
  );
}

export default CourseCard;
