import React, {  useContext } from "react";
import NavbarSection from "./NavbarSection";
import FooterSection from "./FoooterSection";
import ThemeContext from './Themes/ThemeContext';
import "./StylingArena/ErrorPage.css";

const ErrorPage = () => {
    const { darkMode, toggleDarkMode } = useContext(ThemeContext);
  return (
    <div className={`  ${darkMode ? 'dark-mode' : ''}`}>
        <NavbarSection onToggle={toggleDarkMode}/>
      <h1 className="errorhead text-center my-3 py-3">Oops!! 🤕 It looks like this page does not exist!</h1>
      <p className="errortext text-center my-3 py-3">Try Navigating🐝 elsewhere or make sure youre accessing the correct link🙂</p>
      <FooterSection darkMode={darkMode}/>
    </div>
  );
}

export default ErrorPage;