import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import axios from 'axios';
import "../StylingArena/FooterSection.css"


const NewsletterForm = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const API_TOKEN = process.env.REACT_APP_API_TOKEN;
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://backend.techhive.ng/api/newsletter/subscribe/', { email },            {
                headers: {
                    'Authorization': `Token ${API_TOKEN}`,
                    'Content-Type': 'application/json',
                },
            });
            setMessage(response.data.message);
        } catch (error) {
            setMessage('Error: ' + error.response.data.email[0]);
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <h5>Subscribe To Our Newsletter</h5>
            <p>Get notified about new opportunities, articles, jobs, scholarships, hackathons and TechHive events.</p>
            <Form.Control
                type="email"
                placeholder="Your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
            />
           <button className='btn btn-warning my-2' type="submit">Subscribe</button>
                {message && <p>{message}</p>}
            </Form>
        );
    };

    export default NewsletterForm;
